import React, { Fragment } from 'react';
import './HistoricalData.css';
import { Line } from 'react-chartjs-2';
import HistoryDataBoxes from './HistoryDataBoxes/HistoryDataBoxes';
import { DatePicker, DayOfWeek, IDatePickerStrings, Spinner, SpinnerSize } from '@fluentui/react';
import { EDSUrl, GetLanguage } from '../Utility';
import { timeStamp } from 'console';
import backArrow from '../../images/backArrow.png';
import Arrow from '../../images/forwardArrow.png';
import { portalUrl } from '../../components/Utility';
import { IStrings, strings } from '../../loc/mystrings';


export interface IHistoricalDataProps {
  // callback?: any
}

export interface IHistoricalDataState {
  dataIndex: number;
  data?: any;
  additionalData?: any;
  label?: string;
  startDate?: any;
  fromDate?: any;
  toDate?: any;
  rawData?: any;
  loading?: boolean;
}


const locals: IStrings = strings(GetLanguage());



class HistoricalData extends React.Component<IHistoricalDataProps, IHistoricalDataState> {
  constructor(props: IHistoricalDataProps) {
    super(props);
    //this.lineRef = React.createRef();    

    let fromDate = new Date(Date.now());
    fromDate.setDate(fromDate.getDate() - 1);
    let toDate = new Date(Date.now());
    toDate.setDate(fromDate.getDate() + 1);
    this.state = {
      dataIndex: -1,
      startDate: new Date(Date.now()),
      fromDate: fromDate,
      toDate: toDate,
      loading: true
    }
  }

  componentDidMount() {
    this.fetchPowerData(this.state.startDate).then(() => {
      this.GetDataFromDate(this.state.startDate);
    });
  }

  private data = {
    labels: ["0:00", "1:00", "2:00", "3:00", "4:00", "5:00", "6:00", "7:00", "8:00", "9:00", "10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00", "21:00", "22:00", "23:00"],
    datasets: [
      {
        label: 'Større værker',
        index: 0,
        data: [0, 0, 0, 0],
        fill: true,
        backgroundColor: 'rgba(10, 81, 93, 0.7)',
        stacked: true,
        cubicInterpolationMode: 'monotone',
        radius: 0,
      },
      {
        label: 'Mindre anlæg',
        index: 1,
        data: [0, 0, 0, 0],
        fill: true,
        backgroundColor: 'rgba(0, 139, 139, 0.7)',
        stacked: true,
        cubicInterpolationMode: 'monotone',
        radius: 0,

      },
      {
        label: 'Vindmøller',
        index: 2,
        data: [0, 0, 0, 0],
        fill: true,
        backgroundColor: 'rgba(16, 174, 140, 0.7)',
        stacked: true,
        cubicInterpolationMode: 'monotone',
        radius: 0,
      },
      {
        label: 'Solceller',
        index: 3,
        data: [0, 0, 0, 0],
        fill: true,
        bordercolor: 'rgba(255, 212, 36, 0.75)',
        backgroundColor: 'rgba(255, 212, 36, 0.7)',
        stacked: true,
        cubicInterpolationMode: 'monotone',
        radius: 0,
      },
      {
        label: 'PowerTotal',
        index: 4,
        data: [0, 0, 0, 0],
        bordercolor: 'grey',
        backgroundColor: 'grey',
        type: 'bar',
        order: 1
      },
    ],
  };


  private lineRef: any = React.createRef();

  private fetchPowerData = async (selectedDate: Date) => {
    try {
      let toDate = new Date(selectedDate);
      toDate.setDate(selectedDate.getDate() + 1);

      //const response = await fetch(EDSUrl() +'powerhistory');
      let cacheName = "powerHistory" + this.convertDate(selectedDate);

      let view = 'EDSView?cacheName=' + cacheName + '&view=powerhistory?start=' + this.convertDate(selectedDate) + '_end=' + this.convertDate(toDate) + '_offset=0_limit=30';
      const response = await fetch(EDSUrl() + view);
      const jsonStr = await response.json();      
      var json = JSON.parse(jsonStr);

      this.setState({
        rawData: json.records,
        startDate: selectedDate
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  private convertDate(date: Date) {
    //var start = new Date(Date.parse(date)); //Date.parse("2019-07-16"));
    date.setDate(date.getDate());
    var output = date.getFullYear() + "-" + this.fixDatePart(date.getMonth() + 1) + "-" + this.fixDatePart(date.getDate()) + "T00:00"; // '2019-02-01T06:00:00';    
    return output;
  }



  private fixDatePart(part: number) {
    let partStr: string = part.toString();
    if (part < 10)
      partStr = "0" + part.toString();
    return partStr;
  }



  private GetDataFromDate(selectedDate: Date) {

    let ProductionGe100MW: number[] = [];
    let ProductionLt100MW: number[] = [];
    let WindMillTotal: number[] = [];
    let SolarPower: number[] = [];
    //let TimeStamp:string[] = [];

    let CO2Emission: number[] = [];
    let ExchangeSum: number[] = [];
    let PowerTotal: number[] = [];
    let TotalProduction: number[] = [];


    try {
      let selectedDateStr = selectedDate.getFullYear() + "/" + (selectedDate.getMonth() + 1) + "/" + selectedDate.getDate() + " 00:00:00";

      let selectedStartDate = new Date(Date.parse(selectedDateStr));
      this.state.rawData.forEach((powerdata: any) => {

        let TimeStamp = new Date(powerdata.TimeStampUTC);

        let offset = TimeStamp.getTimezoneOffset() * -1;
        //console.log(offset);


        TimeStamp.setMinutes(TimeStamp.getMinutes() + offset);
        //let count:number = 0;

        let selectedEndDate = new Date(Date.parse(selectedDateStr));
        selectedEndDate.setHours(TimeStamp.getHours() + 24);

        if (TimeStamp >= selectedStartDate && TimeStamp <= selectedEndDate) { // && count <= 24) {                                                                
          ProductionGe100MW.unshift(powerdata.ProductionGe100MW);
          ProductionLt100MW.unshift(powerdata.ProductionLt100MW);
          WindMillTotal.unshift(powerdata.WindMillTotal);
          SolarPower.unshift(powerdata.SolarPower);
          CO2Emission.unshift(powerdata.CO2Emission);
          ExchangeSum.unshift(powerdata.ExchangeSum);
          PowerTotal.unshift(powerdata.PowerTotal);

          TotalProduction.unshift(powerdata.ProductionGe100MW + powerdata.ProductionLt100MW + powerdata.WindMillTotal + powerdata.SolarPower);
        }
      });

      this.data.datasets[0].data = ProductionGe100MW;
      this.data.datasets[1].data = ProductionLt100MW;
      this.data.datasets[2].data = WindMillTotal;
      this.data.datasets[3].data = SolarPower;
      this.data.datasets[4].data = PowerTotal;

      let addData: any = [];

      addData[0] = ExchangeSum;
      addData[1] = PowerTotal;
      addData[2] = CO2Emission;
      addData[3] = TotalProduction;


      //this.data.labels = TimeStamp;


      this.setState({ data: this.data, additionalData: addData, startDate: selectedDate, loading: false }, () => {
        let lineChart = this.lineRef.current;
        if (lineChart) {
          lineChart.data.datasets.forEach((dataset: any) => {
            lineChart.data.datasets[dataset.index].data.length = 0;
            this.data.datasets[dataset.index].data.forEach((da: any) => {
              lineChart.data.datasets[dataset.index].data.push(da);
            })
          });
          lineChart.update();
        }
      });
    }
    catch (error) {
      console.log("error", error);
    }
  }

  // componentDidUpdate(prevprops:any, prevState:any) {
  //   console.log('componentDidUpdate');            
  // }        

  private changeDate(date: any) {
    //let dateStr = date.toLocaleString('da-DK', {year: 'numeric', month:'2-digit', day:'2-digit',  hour: '2-digit', hour12: false, minute:'2-digit'});
    this.fetchPowerData(date).then(() => {
      this.GetDataFromDate(date);
    });
  }

  private back() {
    let newDate: Date = new Date(this.state.startDate);
    newDate.setDate(newDate.getDate() - 1);

    this.changeDate(newDate);
  }
  private forward() {
    let newDate: Date = new Date(this.state.startDate);
    newDate.setDate(newDate.getDate() + 1);

    this.changeDate(newDate);
  }
  render() {

    let options = {
      plugins: {
        interaction: {
          mode: 'index'
        },
        tooltip: {
          intersect: false,
          usePointStyle: false,
          xAlign: 'center',
          interaction: {
            mode: 'nearest'
          },
          callbacks: {
            label: (context: any) => {
              if (context.dataIndex !== this.state.dataIndex) {
                this.setState({ dataIndex: context.dataIndex, label: context.label })
              }
            }
          },
          enabled: false,
          external: (e: any) => {
            e.chart.options.customLine.x = e.tooltip.x + 20
          },
        },
        legend: {
          display: false,
        }
      },
      animation: false,
      responsive: true,
      legend: {
        display: false
      },
      hover: {
        mode: 'nearest',
        intersect: false,
      },
      scales: {
        x: {
          title: {
            display: false,
            text: 'Time'
          },
        },
        y: {
          stacked: true,
          title: {
            display: false,
            text: 'Value'
          },
          min: 0,
          max: 10000
        }
      },
      defaultFontFamily: "'DINNextLTPro-Regular', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
      customLine: {
        color: 'black'
      }
    };

    let plugins = [{
      afterDraw: (chart: any, easing: any) => {
        var ctx = chart.ctx;
        var chartArea = chart.chartArea;
        var x = chart.options.customLine.x;
        var center = (chartArea.bottom - chartArea.top) / 2 + chartArea.top;
        var size = 20;

        if (!isNaN(x)) {
          ctx.save();
          ctx.beginPath();
          ctx.strokeStyle = "#000";
          ctx.moveTo(chart.options.customLine.x, chartArea.bottom);
          ctx.lineTo(chart.options.customLine.x, chartArea.top);
          ctx.stroke();
          ctx.fillStyle = "#000000";
          ctx.fillRect(x - size, center - 14, size * 2, size);
          ctx.fillStyle = "#ffffff";
          ctx.textAlign = 'center';
          ctx.fillText(this.state.label, x, center);
          ctx.restore();

        }
      }

    }];

    let hideChart: string = "hide";
    let hideSpinner: string = "";
    if (this.state.loading === false) {
      hideChart = '';
      hideSpinner = 'hide';
    }

    let DayPickerStrings: IDatePickerStrings = {
      months: [
        'Januar',
        'Februar',
        'Marts',
        'April',
        'Maj',
        'Juni',
        'Juli',
        'August',
        'September',
        'Oktober',
        'November',
        'December'
      ],

      shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'],

      days: ['Søndag', 'Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lørdag'],

      shortDays: ['S', 'M', 'T', 'O', 'T', 'F', 'S'],

      goToToday: 'Hop til idag',
      prevMonthAriaLabel: 'Go to previous month',
      nextMonthAriaLabel: 'Go to next month',
      prevYearAriaLabel: 'Go to previous year',
      nextYearAriaLabel: 'Go to next year'
    };

    if (GetLanguage() === "en-US") {

      DayPickerStrings = {
        months: [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December'
        ],

        shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],

        days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],

        shortDays: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],

        goToToday: 'Go to today',
        prevMonthAriaLabel: 'Go to previous month',
        nextMonthAriaLabel: 'Go to next month',
        prevYearAriaLabel: 'Go to previous year',
        nextYearAriaLabel: 'Go to next year'
      };
    }

    return (
      <div id="HistoricalData">
        <div className="dateContainer">
          <div className='selectDateText'>{locals.SelectDate}</div>
          <div className='selectDate'>
          <div className='back'><img onClick={this.back.bind(this)} src={backArrow} alt='back' /></div> 
          <DatePicker className="datepicker"
          formatDate={ (date? : Date):string =>{ 
            if(!date) return '';
            return  date.getDate() +"-" + (date.getMonth()+1) +"-" + date.getFullYear() 
            
            }
          }
            strings={DayPickerStrings}
            minDate={new Date(Date.parse("2018-11-01"))}
            maxDate={new Date(Date.now())}
            firstDayOfWeek={1}
            value={this.state.startDate} onSelectDate={this.changeDate.bind(this)} />
          <div className='forward'><img  onClick={this.forward.bind(this)} src={Arrow} alt='forward' /></div>
          </div>
        </div>

        <Spinner className={hideSpinner} size={SpinnerSize.large} />
        <div className={hideChart}>

          <Line id='chart' className="chart" ref={this.lineRef} data={this.state.data} options={options} plugins={plugins} />
          <HistoryDataBoxes dataIndex={this.state.dataIndex} data={this.state.data} additionalData={this.state.additionalData} />
        </div>

      </div>
    );
  }
}

export default HistoricalData;