import React from 'react';
export interface PowerplantProps {
    posx:string;
    posy:string;
}
  
class Powerplant extends React.Component<PowerplantProps, {}> {    
    render() {        
        let transformData:string = `translate(${this.props.posx} ${this.props.posy})`
        return ( 
            <use width="4" height="4" transform={transformData} xlinkHref="#powerplant"></use>
         );
    }
}
 
export default Powerplant;