import React from 'react';
export interface BioMethaneConnectionProps {
    posx:string;
    posy:string;
}
  
class BioMethaneConnection extends React.Component<BioMethaneConnectionProps, {}> {    
    render() {        
        let transformData:string = `translate(${this.props.posx} ${this.props.posy})`
        return ( 
            <g id="BioMethaneConnection">
                <use width="5.5" height="5.5" transform={transformData} xlinkHref="#biomethaneconnection"></use>      
            </g>
         );
    }
}
 
export default BioMethaneConnection;