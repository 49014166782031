import React from 'react';
import './WindmillSymbol.css';

class WindmillSymbol extends React.Component<{}, {}> {    
    render() { 
        return ( 
            <symbol id="windmill" data-name="windmill" viewBox="0 0 114.2 139.1">
                <g>                                            
                    <g id="fod">
                        <line id="Fod" className="st0" x1="34" y1="137.8" x2="80.3" y2="137.8" />
                        <path id="stav" className="st0" d="M61.1,137.4l-2-75.2c0,0-1.5-0.4-2.1-0.4s-1.7,0.4-1.7,0.4l-2,75.2" />
                    </g>
                    <g id="vinger">
                        <g>
                            <g id="vingersamlet">
                                <path className="animate-Windmill" d="M60,52.7L57.5,2.6C57,1.9,56,1.7,55.3,2.2c-0.2,0.1-0.3,0.2-0.4,0.4l-2.5,44.5l3,5.7 M52.9,56.1L10.6,85.6
                                    c-0.4,0.8,0,1.7,0.8,2.1c0.2,0.1,0.4,0.1,0.6,0.1l39.1-21.3l3.3-5.6 M61.5,58.8v-3.2c0-1.4-1.1-2.5-2.5-2.5h-3.2
                                    c-1.4,0-2.5,1.1-2.5,2.5v3.2c0,1.4,1.1,2.5,2.5,2.5H59C60.4,61.3,61.5,60.2,61.5,58.8L61.5,58.8z M59.4,61.6l42.9,26.1
                                    c0.9,0,1.6-0.7,1.6-1.6c0-0.2,0-0.4-0.1-0.6l-35.4-27L62,57.8" />
                            </g>                                                        
                        </g>
                    </g>
                </g>
            </symbol>
         );
    }
}
 
export default WindmillSymbol;