import React from 'react';
import './PowerIconBox.css';
import { GetLanguage } from '../Utility';
import { IStrings, strings } from '../../loc/mystrings';
const locals:IStrings = strings(GetLanguage());
  
class PowerIconBox extends React.Component<{}, {}> {
        
    render() {            
        return ( 
            <div id="powerIconBox">
                <div className="map-sidebar-box">
                <h3 >{locals.SymbolDescription }</h3>                    
                    <ul className="no-bullet">
                        <li><span className="title"> {locals.PowerPlantGt100Mw}</span><span className="icon icon-centralt-kraftvaerk"></span></li>
                        <li><span className="title"> { locals.OffshoreWindFarm }</span><span className="icon icon-windmill"></span></li>
                        <li><span className="title"> { locals.ConverterSubstation }</span><span className="icon icon-transitpunkt"></span></li>
                        <li><span className="title"> { locals.OverheadLinesAc }</span><span className="icon icon-luftledning-vekselsstroem"></span></li>
                        <li><span className="title"> { locals.CableAc }</span><span className="icon icon-kabel-vekselsstroem"></span></li>
                        <li><span className="title"> { locals.OverheadLinesDc }</span><span className="icon icon-luftledning-jaevnstroem"></span></li>
                        <li><span className="title"> { locals.CableDc}</span><span className="icon icon-kabel-jaevnstroem"></span></li>
                    </ul>                    
                </div>
            </div>

         );
    }
}
 
export default PowerIconBox;