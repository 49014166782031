import React from 'react';
import './GasIconBox.css';
import { GetLanguage } from '../Utility';
import { IStrings, strings } from '../../loc/mystrings';
const locals:IStrings = strings(GetLanguage());
  
class PowerIconBox extends React.Component<{}, {}> {
        
    render() {            
        return ( 
            <div id="GasiconBox">
                <div className="map-sidebar-box">
                    <h3 >{locals.SymbolDescription }</h3>                    
                    <ul className="no-bullet">
                        <li><span className="title"> {locals.GasStorage }</span><span className="icon icon-gaslager"></span></li>
                        <li><span className="title">{locals.PointOfTransit }</span><span className="icon icon-transitpunkt"></span></li>
                        <li><span className="title">{locals.Mrstation }</span><span className="icon icon-maalerstation"></span></li>
                        <li><span className="title">{locals.Measurements }</span><span className="icon icon-centralt-kraftvaerk"></span></li>
                        {/* <li><span className="title">BIOGASANLÆG</span><span className="icon icon-biogasanlaeg"></span></li> */}
                        <li><span className="title">{locals.GasPipe }</span><span className="icon icon-luftledning-vekselsstroem"></span></li>
                        <li><span className="title">{locals.GasPipeOwnedByOthers }</span><span className="icon icon-gasroer-andre"></span></li>
                        <li><span className="title">{locals.BiomethaneConnection }</span><span className="icon icon-biogas-connection"></span></li>
                    </ul>
                </div>
            </div>
         );
    }
}
 
export default PowerIconBox;