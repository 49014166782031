import React, { Fragment } from 'react';
import { IStrings, strings } from '../../loc/mystrings';
import { GetLanguage } from '../Utility';
import './MapNotUpdated.css';
export interface IMapNotUpdatedProps {
    date?: any;
    controlData: any;
    powerDataState?: string;
    gasDataState?: string;
}

export interface IMapNotUpdatedState {
    date?: any;
    controlData: any;
    powerDataState?: string;
    gasDataState?: string;
}

const locals: IStrings = strings(GetLanguage());

class MapNotUpdated extends React.Component<IMapNotUpdatedProps, IMapNotUpdatedState> {
    constructor(props: IMapNotUpdatedProps) {
        super(props);
        this.state = { date: this.props.date, controlData: this.props.controlData, powerDataState: this.props.powerDataState };
    }


    componentDidUpdate(prevprops: IMapNotUpdatedState) {
        //prevprops.date !== this.props.date || prevprops.controlData !== this.props.controlData ||
        // if (this.props && (prevprops.dataState.DataError !== this.state.dataState.DataError)) {            
        //             console.log('change event');
        //             this.setState({ dataState: this.props.dataState });
        // }

        if (this.props && this.props.powerDataState !== prevprops.powerDataState)
            this.setState({ powerDataState: this.props.powerDataState });

        if (this.props && this.props.gasDataState !== prevprops.gasDataState)
            this.setState({ gasDataState: this.props.gasDataState });

        if (this.props && this.props.date && prevprops.date !== this.props.date)
            this.setState({
                date: this.props.date
            });

    }

    render() {        
        let style: React.CSSProperties | object = {
            display: 'inline-block',
            padding: '4px',
            textTransform: 'uppercase',
            color: '#02525e',
        }

        let headline: React.CSSProperties | object = {
            fontSize: '.3000rem',
            fontFamily: "DINNextLTPro-Medium",
            textAlign: 'left',
            fontStyle:'italic'
        }        

        let displayText = '';            
        let showError =false;

        if (this.props.controlData.powerTypeGas) {
            if (this.state.gasDataState === 'Error')
            {
                displayText = locals.GasDataError;
                showError=true;
            }
            else if (this.state.gasDataState === 'Olddata'){            
                displayText = locals.GasDataMissing;
                showError=true;
            }
        } 
        else if (this.props.controlData.powerTypeEl)  {
            if (this.state.powerDataState === 'Error') {
                displayText = locals.PowerDataError;
                showError=true;
            } else if (this.state.powerDataState === 'Olddata') {
                displayText = locals.PowerDataMissing;
                showError=true;
            }
        }

        

        

        let datasetDate = new Date(this.state.date);
        let dateString = datasetDate.toLocaleString(GetLanguage(), { day: 'numeric', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit' });

        displayText = displayText.replace('[DateTime]', dateString);

        return (
            <Fragment>
                {showError &&
                    <foreignObject x='6.5' y='40' width="120" height="40">
                        <div style={style}>
                            <div id="mapNotUpdatedWarning" style={headline}>{displayText}</div>
                        </div>
                    </foreignObject>
                }
            </Fragment>
        );
    }
}

export default MapNotUpdated;





