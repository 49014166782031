import React from 'react';
import { IStrings, strings } from '../../loc/mystrings';
import { GetLanguage } from '../Utility';
import './LastUpdated.css';
export interface ILastUpdatedProps {
    date?:any;
}

export interface ILastUpdatedState {
    date?:any;
}

const locals:IStrings = strings(GetLanguage());

class LastUpdated extends React.Component<ILastUpdatedProps, ILastUpdatedState> {   
    constructor(props: ILastUpdatedProps) {
        super(props);        
        this.state = { date : this.props.date};
    }    

    componentDidUpdate(prevprops:ILastUpdatedProps) {              
        if(this.props && this.props.date && prevprops.date !== this.props.date)
        this.setState({ 
            date : this.props.date
        });                
    }      

    render() {                
        let style:React.CSSProperties|object = {
            display: 'inline-block',                        
            padding: '4px',            
            textTransform: 'uppercase',
            color: '#02525e',                        
        }
    
        let headline:React.CSSProperties|object = {            
            fontSize: '.3000rem',                    
            fontFamily: "DINNextLTPro-Medium",
            textAlign: 'right'
        }
    
        let date:React.CSSProperties|object = {            
            fontSize: '.3000rem',                    
            fontFamily: "DINNextLTPro-Regular",
            textAlign: 'right'
        }

        //console.log(this.state.date);
        let dkDate = new Date(this.state.date);
        

        return (      
            <foreignObject x='430' y='275' width="200" height="50">                        
                <div style={style}>                        
                        <div id="mapLastUpdatedText" style={headline}>{locals.LastUpdate}</div>
                        <div id="mapLastUpdatedDatetime" style={date} >{dkDate.toLocaleString(GetLanguage(), {day:'numeric', month: 'short', year:'numeric', hour:'2-digit', minute:'2-digit'} )}</div>
                    </div>
            </foreignObject>
        );
    }
}

export default LastUpdated;





