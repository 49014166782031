import React from 'react';
import './GasJunctionSymbol.css';

class GasJunctionSymbol extends React.Component<{}, {}> {        
    render() {                
        return ( 
            <React.Fragment>        
            <symbol id="junction_02" data-name="junction_02" viewBox="0 0 4 4">
                <circle className="GasJunction-1" cx="2" cy="2" r="1.5"></circle>
                <path className="GasJunction-4" d="M2,1A1,1,0,1,1,1,2a0.94,0.94,0,0,1,.88-1H2M2,0A2,2,0,1,0,4,2,2,2,0,0,0,2,0H2Z"></path>
            </symbol>
            <symbol id="gaslager_icon_new" data-name="centralt_kraftvaerk" viewBox="0 0 14.99 14.99">
                <polygon className="triangle" points="6.3,4 1,12 12,12"></polygon>
            </symbol>
        </React.Fragment>
         );
    }
}
 
export default GasJunctionSymbol;