import React from 'react';
import { IPowerBoxProps } from '../PowerBox/PowerBox';
import { GetLanguage, formatAbsNumberMW } from '../Utility';
import { IStrings, strings } from '../../loc/mystrings';
import GasStorageLabel from './GasStorageLabel';
const locals: IStrings = strings(GetLanguage());

export interface IGasStorageLabelsState {
    data: any[];
}



class GasStorageLabels extends React.Component<IPowerBoxProps, IGasStorageLabelsState> {
    constructor(props: IPowerBoxProps,) {
        super(props);
        this.state = { data: [] };
    }

    componentDidUpdate(prevprops: IPowerBoxProps) {
        if (this.props && this.props.data && prevprops.data !== this.props.data) {
            this.updateLabels();
        }
    }


    componentDidMount() {
        if (this.props && this.props.data) {
            this.updateLabels();
        }
    }

    private updateLabels() {
        let dataArray: any[] = [];
        let vLabel: string;
        let exportString = locals.Export;
        let importString = locals.Import;
        let data = this.props.data;
        
        //exportSweden        
        dataArray.push({ label: locals.Export, value: data.exportSweden, xpos: '290', ypos: '150' });

        //norwegianImport
        dataArray.push({ label: 'NORWEGIAN ' + locals.Import, value: data.norwegianImport, xpos: '20', ypos: '145' });

        //danishImport         
        if (data.danishImport < 0)
            data.danishImport = 0;
        dataArray.push({ label: 'DANISH ' + locals.Import, value: data.danishImport, xpos: '34', ypos: '177' });
        
        //importFaxe
        data.importFaxe > 0 ? vLabel = importString : vLabel = exportString;
        dataArray.push({ label: vLabel, value: data.importFaxe, xpos: '280', ypos: '225' });
        
        //importEllund        
        data.importEllund > 0 ? vLabel = importString : vLabel = exportString;
        dataArray.push({ label: vLabel, value: data.importEllund, xpos: '104', ypos: '250' });

        this.setState({
            data: dataArray
        });
    }

    render() {
        let key: number = 1;
        return (
            this.state && this.state.data.map((itm) => {
                return <GasStorageLabel key={key++} flow={itm.value} label={itm.label} xpos={itm.xpos} ypos={itm.ypos} />
            })

        );
    }
}

export default GasStorageLabels;

