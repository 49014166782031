import React from 'react';
import './TransformerStationSymbol.css';

class TransformerStationSymbol extends React.Component<{}, {}> {    
    render() { 
        return ( 
            <symbol id="transformerstation" data-name="transformerstation" viewBox="0 0 4 4">
                <circle className="cls-4" cx="2" cy="2" r="1.5"></circle>
                <path className="cls-1" d="M2,1A1,1,0,1,1,1,2a0.94,0.94,0,0,1,.88-1H2M2,0A2,2,0,1,0,4,2,2,2,0,0,0,2,0H2Z"></path>
            </symbol>
         );
    }
}
 
export default TransformerStationSymbol;