import React, { Fragment } from 'react';
import { IStrings, strings } from '../../loc/mystrings';
import { GetLanguage } from '../Utility';
import './DailyUpdate.css';
export interface IDailyUpdateProps {
    date?: any;
    controlData: any;    
}

export interface IDailyUpdateState {
    date?: any;    
}

const locals: IStrings = strings(GetLanguage());

class DailyUpdate extends React.Component<IDailyUpdateProps, IDailyUpdateState> {
    constructor(props: IDailyUpdateProps) {
        super(props);
        this.state = { date: this.props.date};
    }

    componentDidUpdate(prevprops: IDailyUpdateProps) {        
        if (this.props && (prevprops.date !== this.props.date))
            this.setState({date: this.props.date});                
    }

    render() {
        let style: React.CSSProperties | object = {
            display: 'inline-block',
            padding: '4px',
            textTransform: 'uppercase',
            color: '#02525e',
        }

        let headline: React.CSSProperties | object = {
            fontSize: '.3000rem',
            fontFamily: "DINNextLTPro-Medium",
            textAlign: 'right'
        }

        let date: React.CSSProperties | object = {
            fontSize: '.3000rem',
            fontFamily: "DINNextLTPro-Regular",
            textAlign: 'right'
        }
        // (controlData.powerTypeGas && controlData.viewTypeMap) && 
                
        return (
            <Fragment>        
                    <foreignObject x='6.5' y='30' width="200" height="30">
                        <div style={style}>
                            <div id="mapDailyUpdateText" style={headline}>{locals.DailyUpdate}</div>
                        </div>
                    </foreignObject>                
            </Fragment>
        );
    }
}

export default DailyUpdate;





