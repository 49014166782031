import React from 'react';
import { IPowerBoxProps } from '../PowerBox/PowerBox';
import PowerLabel from './PowerLabel';
import { GetLanguage } from '../Utility';
import { IStrings, strings } from '../../loc/mystrings';
const locals:IStrings = strings(GetLanguage());

export interface IPowerLabelsState {
    data: any[];    
}

class PowerLabels extends React.Component<IPowerBoxProps, IPowerLabelsState> {    
    constructor(props: IPowerBoxProps,) {
        super(props);        
        this.state = { data : []};
    }   

    componentDidMount(){
        if(this.props && this.props.data){
            this.updateLabels();           
        }
    }

    componentDidUpdate(prevprops:IPowerBoxProps) {        
        if(this.props && this.props.data && prevprops.data !== this.props.data){
            this.updateLabels();           
        }    
    }

    private updateLabels() {
        let dataArray:any[]=[];
        let vLabel:string;
                
        let data = this.props.data;
        let value = data["Exchange_DK1_NO"];
        value<0?vLabel=locals.Export +": ":vLabel=locals.Import + ": ";     
        dataArray.push({label:locals.JutlandNorway, value:value, xpos:'75', ypos:'65',valueLabel:vLabel });
                  
        value = data["Exchange_DK1_SE"];
        value<0?vLabel=locals.Export +": ":vLabel=locals.Import + ": ";     
        dataArray.push({label:locals.JutlandSweden, value:value, xpos:'186', ypos:'68',valueLabel:vLabel});
        
        value = data["Exchange_DK1_NL"];
        value<0?vLabel=locals.Export +": ":vLabel=locals.Import + ": ";     
        dataArray.push({label:locals.JutlandNetherlands, value:value, xpos:'35', ypos:'210',valueLabel:vLabel});
        
        value = data["Exchange_DK1_DE"]; 
        value<0?vLabel=locals.Export +": ":vLabel=locals.Import + ": ";     
        dataArray.push({label:locals.JutlandGermany, value:value, xpos:'100', ypos:'250',valueLabel:vLabel});
        
        value = data["Exchange_DK1_DK2"];    
        value<0?vLabel="→":vLabel="←";     
        dataArray.push({label:locals.TheGreatBelt, value:value, xpos:'190', ypos:'200',valueLabel:vLabel});
        
        value = data["Exchange_DK2_SE"];  
        value<0?vLabel=locals.Export +": ":vLabel=locals.Import + ": ";     
        dataArray.push({label:locals.ZealandSweden, value:value, xpos:'270', ypos:'140',valueLabel:vLabel});
        
        value = data["Exchange_DK2_DE"];        
        value<0?vLabel=locals.Export +": ":vLabel=locals.Import + ": ";     
        dataArray.push({label:locals.ZealandGermany, value:value, xpos:'250', ypos:'240',valueLabel:vLabel});
        
        value = data["Exchange_Bornholm_SE"]; 
        value<0?vLabel=locals.Export +": ":vLabel=locals.Import + ": ";      
        dataArray.push({label:locals.BornholmSweden, value:value, xpos:'310', ypos:'210',valueLabel:vLabel});

        value = data["Exchange_DK1_GB"]; 
        value<0?vLabel=locals.Export +": ":vLabel=locals.Import + ": ";      
        dataArray.push({label:locals.VikingLink, value:value, xpos:'20', ypos:'185',valueLabel:vLabel});
        
          
        this.setState({
            data: dataArray
        });      
    }
 

    render() {
        let key:number = 1;
        return (            
            this.state && this.state.data.map((itm) => {
             return <PowerLabel key={key++} flow={itm.value} label={itm.label} xpos={itm.xpos} ypos={itm.ypos} valueLabel={itm.valueLabel} />
            })
            
        );
    }

    // private formatAbsNumber(value:number) {
    //     let num:number = Math.round(value);
    //     num = Math.abs(num);
    //     return num.toLocaleString('da-DK');
    //  }
}

export default PowerLabels;   

