import { Checkbox } from '@fluentui/react/lib/components/Checkbox/Checkbox';
import { ChoiceGroup, IChoiceGroupOption } from '@fluentui/react/lib/components/ChoiceGroup';
import React from 'react';
import './ControlBar.css';
import { Stack } from '@fluentui/react/lib/components/Stack';
import { initializeIcons } from '@fluentui/react/lib/Icons';
import { IStrings, strings } from '../../loc/mystrings';
import { GetLanguage } from '../Utility';


initializeIcons(/* optional base url */);

export interface IControlBarProps {
    callback?: any
}

export interface IControlBarState {
    viewTypeMap: boolean;
    viewTypeHistory: boolean;
    powerTypeEl: boolean;
    powerTypeGas: boolean;
    showMeassure: boolean;
    showLegends: boolean;
    PowerTypeKey: string;
    MapTypeKey: string;
    convertToGasStorage: boolean;
}

const locals: IStrings = strings(GetLanguage());

const mapOptions: IChoiceGroupOption[] = [
    { key: 'A', text: locals.Map },
    { key: 'B', text: locals.HistoricalData },
];

const stackTokens = { childrenGap: 10 };

class ControlBar extends React.Component<IControlBarProps, IControlBarState> {
    constructor(props: IControlBarProps) {
        super(props);
        this.state = { convertToGasStorage: false, viewTypeMap: true, viewTypeHistory: false, powerTypeEl: true, powerTypeGas: false, showMeassure: true, showLegends: true, PowerTypeKey: 'A', MapTypeKey: 'A' };
    }

    componentDidMount() {
        //Disable GAS
        if (document.location.search.indexOf('map=gasstorage') !== -1) {
            this.setState({
                convertToGasStorage: true,
                powerTypeGas: true,
                powerTypeEl: false,
                viewTypeMap: true,
                PowerTypeKey: 'B',
                MapTypeKey: 'A',
                showLegends: false,
                showMeassure: false
            }, () => { this.props.callback(this.state) });
        }
        
        //Disable GAS
        if (document.location.search.indexOf('system=gas') !== -1) {
            this.setState({
                powerTypeGas: true,
                powerTypeEl: false,
                viewTypeMap: true,
                PowerTypeKey: 'B',
                MapTypeKey: 'A',
                showLegends: true,
                showMeassure: true
            }, () => { this.props.callback(this.state) });
        }
        else 
        
        if (document.location.search.indexOf('system=el') !== -1) {
            this.setState({
                powerTypeGas: false,
                powerTypeEl: true,
                viewTypeMap: true,
                PowerTypeKey: 'A',
                MapTypeKey: 'A'
            }, () => { this.props.callback(this.state) });
        }
        else if (document.location.search.indexOf('system=history') !== -1) {
            this.setState({
                powerTypeGas: false,
                powerTypeEl: true,
                viewTypeMap: false,
                viewTypeHistory: true,
                PowerTypeKey: 'A',
                MapTypeKey: 'B'
            }, () => { this.props.callback(this.state) });
        }

        // this.reload(10000).then(()=> {                                    
        //     if(this.state.MapTypeKey==='A')
        //         this.props.callback(this.state);
        // });
    }



    //  private reload = (ms:number) => {
    //     return new Promise((resolve, reject) => setTimeout(resolve, ms));
    //   };

    private dataOptions: IChoiceGroupOption[] = [
        { key: 'A', text: locals.TheElectricityNetRightNow, }
        //Disable GAS
        ,{ key: 'B', text: locals.TheGasNetRightNow }
    ];

    private toggleMap() {
        let mapKey = this.state.viewTypeMap ? 'B' : 'A';
        let powerKey = this.state.PowerTypeKey;
        let powerTyepEl = this.state.powerTypeEl;
        let powerTyepGas = this.state.powerTypeGas;
        let viewTypeMap = !this.state.viewTypeMap;
        let viewTypeHistory = !this.state.viewTypeHistory;
        // if (mapKey === 'B') {
        //     powerKey = 'A';
        //     powerTyepEl = true;
        //     powerTyepGas = false;
        // }
        //console.log(powerKey);
        this.setState({
            viewTypeMap: viewTypeMap,
            viewTypeHistory: viewTypeHistory,
            MapTypeKey: mapKey,
            PowerTypeKey: powerKey,
            powerTypeEl: powerTyepEl,
            powerTypeGas: powerTyepGas
        }, () => {
            // this.reload(10000).then(()=> {                
            //     if(this.state.MapTypeKey==='A')
            //     this.props.callback(this.state);
            // });
            this.props.callback(this.state);
        });
    }

    private toggleData() {
        let powerKey = this.state.powerTypeEl ? 'B' : 'A';
        this.setState({
            powerTypeEl: !this.state.powerTypeEl,
            powerTypeGas: !this.state.powerTypeGas,
            PowerTypeKey: powerKey
        }, () => { this.props.callback(this.state) });
    }

    private toogleMeassure() {
        this.setState({
            showMeassure: !this.state.showMeassure
        }, () => { this.props.callback(this.state) });
    }

    private toogleLegends() {
        this.setState({
            showLegends: !this.state.showLegends
        }, () => { this.props.callback(this.state) });
    }

    render() {

        //Disable GAS
        //this.dataOptions[1].disabled = this.state.viewTypeHistory;

        return (
            <React.Fragment>
                {!this.state.convertToGasStorage &&
                    <div className="FullWidth">
                        <div id="ControlBar">
                            <div className="introduction">
                                <h1 className="section_headline">{locals.TheEnergySystemRightNow}</h1>
                                <p></p>
                            </div>
                            <div className="row">
                                <div className="mode_section_column">
                                    <ChoiceGroup defaultSelectedKey="A" className="radio" options={mapOptions}
                                        onChange={this.toggleMap.bind(this)} label={locals.DisplayMode} selectedKey={this.state.MapTypeKey} />
                                </div>
                                <div className="area_section_column">
                                    <ChoiceGroup defaultSelectedKey="A" className="radio" options={this.dataOptions}
                                        onChange={this.toggleData.bind(this)} label={locals.Area} selectedKey={this.state.PowerTypeKey} />
                                </div>
                                {!this.state.viewTypeHistory &&
                                    <div className="section_column">
                                        <div className="ms-Label">{locals.Include}</div>
                                        <Stack tokens={stackTokens} className="inkluder">
                                            <Checkbox className="radio" label={locals.Measurements} checked={this.state.showMeassure} onChange={this.toogleMeassure.bind(this)} />
                                            <Checkbox className="radio" label={locals.SymbolDescription} checked={this.state.showLegends} onChange={this.toogleLegends.bind(this)} />
                                        </Stack>
                                    </div>}
                            </div>
                        </div>
                    </div>
                }
            </React.Fragment>
        );
    }
}

export default ControlBar;