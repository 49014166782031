import React, { Fragment } from 'react';
import gasStorageAni from '../../images/GasStorageAnimation.gif';
import { portalUrl } from '../../components/Utility';
import { relative } from 'path';

export interface IGasStorageState {
    convertToGasStorage: boolean;
}

export interface IGasStorageProps {
    convertToGasStorage: boolean;
}

class GasStorage extends React.Component<IGasStorageProps, IGasStorageState> {

    componentDidMount() {
        this.setState(this.props);
    }
    render() {               
        let titleStyleTorup: React.CSSProperties | object = {
            fontSize: '0.4000rem',
            fontFamily: "BrandonGrotesqueWeb-Regular",
            color: '#FFF',
            display:'inline',
            position: 'relative',
            left:'-25px',
            top:'10px'
        }

        let titleStyleStenlille: React.CSSProperties | object = {
            fontSize: '0.4000rem',
            fontFamily: "BrandonGrotesqueWeb-Regular",
            color: '#FFF',
            display:'inline',
            position: 'relative',
            left:'-25px',
            top:'10px'
        }

        return (
            <React.Fragment>
                {!this.state?.convertToGasStorage &&
                    <g id="gaslager">
                        <use width="6" height="6" transform="translate(227.78 187)" xlinkHref="#gaslager_icon_new"></use>
                        <use width="6" height="6" transform="translate(139 111)" xlinkHref="#gaslager_icon_new"></use>
                    </g>
                }

                {this.state?.convertToGasStorage &&
                <Fragment>
                    <foreignObject x='120' y='100' width="200" height="40">
                        <div>
                            <img src={gasStorageAni} width="35px" alt='Gasstorage' />
                            <div style={titleStyleTorup}>LILLE TORUP</div>
                        </div>
                    </foreignObject>
                     <foreignObject x='215' y='177' width="200" height="40">
                     <div>
                         <img src={gasStorageAni} width="35px" alt='Gasstorage' />
                         <div style={titleStyleStenlille}>STENLILLE</div>                         
                     </div>
                 </foreignObject>
                 </Fragment>
                }
            </React.Fragment>
        );
    }
}

export default GasStorage;