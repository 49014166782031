import React from 'react';
import './BioMethaneConnectionSymbol.css';

class BioMethaneConnectionSymbol extends React.Component<{}, {}> {        
    render() {                
        return ( 
            <React.Fragment>
            <symbol id="biomethaneconnection" data-name="biomethaneconnection" viewBox="0 -5.5 20 20" >
              <rect className="biomethaneconnection" x="0" y="0" width="12" height="12"/>
            </symbol>       
        </React.Fragment>
         );
    }
}
 
export default BioMethaneConnectionSymbol;