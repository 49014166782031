import React from 'react';
import './HistoryDataBoxes.css';
import { formatRoundNumber, GetLanguage } from '../../Utility';
import { IStrings, strings } from '../../../loc/mystrings';
const locals:IStrings = strings(GetLanguage());

export interface IHistoryDataBoxesProps {
    dataIndex:number;    
    data:any;    
    additionalData:any;
}

export interface IHistoryDataBoxesState {
    dataIndex?:number;
}

class HistoryDataBoxes extends React.Component<IHistoryDataBoxesProps, IHistoryDataBoxesState> {
    constructor(props: IHistoryDataBoxesProps) {
        super(props);        
        this.state = { dataIndex : this.props.dataIndex};                
    }    

    componentDidUpdate(prevprops:IHistoryDataBoxesProps) {                             
        if(this.props && prevprops.dataIndex !== this.props.dataIndex) {            
            this.setState({ 
                dataIndex : this.props.dataIndex
            });                            
        }
    }

  

    // private formatNumber(value:number) {
    //     let num:number = Math.round(value);
    //     return num.toLocaleString();
    // }
            
    render() {            
        // let ddata = -1; 
                
        // console.log(this.state.Tooltip.dataset);

        // if(this.state.Tooltip && this.state.Tooltip.dataset) {
        //      ddata = this.state.Tooltip.dataset.data[this.state.Tooltip.dataIndex];                                       
        // }
        //     console.log('ddata');
            // console.log(this.state.Tooltip.data);
        let dataIndex=0;

        if(this.state.dataIndex)
            dataIndex = this.state.dataIndex;
            

        return (       
            <React.Fragment>
            <div id="HistoryDataBoxes">                    
                <div className="databox">
                   <div className="headlabel DarkGreen" >{locals.PowerPlantGt100Mw}</div>
                   <div className="output"><span className="value">{formatRoundNumber(this.props?.data?.datasets[0].data[dataIndex])}</span><span className="unit">MW</span></div>
                </div>
                <div className="databox">
                   <div className="headlabel Green" >{locals.PowerPlantsLt100}</div>
                   <div className="output"><span className="value">{formatRoundNumber(this.props?.data?.datasets[1].data[dataIndex])}</span><span className="unit">MW</span></div>
                </div>
                <div className="databox">
                   <div className="headlabel LightGreen" >{locals.WindTurbines}</div>
                   <div className="output"><span className="value">{formatRoundNumber(this.props?.data?.datasets[2].data[dataIndex])}</span><span className="unit">MW</span></div>
                </div>
                <div className="databox">
                   <div className="headlabel Yellow" >{locals.SolarCells}</div>
                   <div className="output"><span className="value">{formatRoundNumber(this.props?.data?.datasets[3].data[dataIndex])}</span><span className="unit">MW</span></div>
                </div>
            </div>  
            {this.props.additionalData && 
            <div id="HistoryDataBoxes">                    
                <div className="databox">
                   <div className="headlabel Grey" >{locals.NetExchangeImport}</div>
                   <div className="output"><span className="value">{formatRoundNumber(this.props?.additionalData[0][dataIndex])}</span><span className="unit">MW</span></div>
                </div>
                <div className="databox">
                   <div className="headlabel DarkGrey" >{locals.ElectricityConsumption}</div>
                   <div className="output"><span className="value">{formatRoundNumber(this.props?.additionalData[1][dataIndex])}</span><span className="unit">MW</span></div>
                </div>
                <div className="databox">
                   <div className="headlabel Grey" >{locals.Co2Emissions}</div>
                   <div className="output"><span className="value">{formatRoundNumber(this.props?.additionalData[2][dataIndex])}</span><span className="unit">G/KWH</span></div>
                </div>
                <div className="databox">
                   <div className="headlabel Grey" >{locals.TotalProduction}</div>
                   <div className="output"><span className="value">{formatRoundNumber(this.props?.additionalData[3][dataIndex])}</span><span className="unit">MW</span></div>
                </div>
            </div>  }
            </React.Fragment>        
                    
         );
    }    
}
 
export default HistoryDataBoxes;