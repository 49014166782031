import React from 'react';
import { IStrings, strings } from '../../loc/mystrings';
import { GetLanguage } from '../Utility';
import './HistoryDatePicker.css';
import { DatePicker, IDatePickerStrings } from '@fluentui/react';
import backArrow from '../../images/backArrow.png';
import Arrow from '../../images/forwardArrow.png';


export interface IHistoryDatePickerProps {    
    callback:any;
    date?:any;
}

export interface IHistoryDatePickerState {
    date?:any;
}

const locals: IStrings = strings(GetLanguage());

class HistoryDatePicker extends React.Component<IHistoryDatePickerProps, IHistoryDatePickerState> {   
    constructor(props: IHistoryDatePickerProps) {
        super(props);        
        this.state = { date : this.props.date};        
    }    

    componentDidUpdate(prevprops:IHistoryDatePickerProps) {              
        if(this.props && this.props.date && prevprops.date !== this.props.date)
        this.setState({ 
            date : this.props.date
        });                
    }      

    private changeDate(date: any) {
        //let dateStr = date.toLocaleString('da-DK', {year: 'numeric', month:'2-digit', day:'2-digit',  hour: '2-digit', hour12: false, minute:'2-digit'});        
        this.props.callback(date);
      }
      private back() {
        let newDate: Date = new Date(this.state.date);
        newDate.setDate(newDate.getDate() - 1);
    
        this.changeDate(newDate);
      }
      private forward() {
        let newDate: Date = new Date(this.state.date);
        newDate.setDate(newDate.getDate() + 1);
    
        this.changeDate(newDate);
      }

    render() {                           
        let box:React.CSSProperties|object = {                                               
            textTransform: 'uppercase',
            color: '#02525e',                    
            padding:'0px',
            //border: 'solid 1px black',                                                                
        }           

        let back:React.CSSProperties|object = { 
          height: '7px',          
          cursor: 'pointer',
          position:'relative',
          left:'11px',
          top:'-15px',    
          float:'left'
        }

        let forward:React.CSSProperties|object = { 
          height: '7px',                    
          left:'57px',
          top:'-62px',    
          cursor: 'pointer',
          position:'relative',
          display:'inline'
        }               
        
        let datepicker:React.CSSProperties|object = {
          position: 'relative',            
          scale:'0.33',
          color: '#02525e',        
          left:'-22px',
          top:'-27px',                  
          zIndex:'0',
          marginLeft:'1px',       
          width:'112px'                 
      }

      let selectDate:React.CSSProperties|object = {     
        position: 'relative',    
        width:'112px',
        scale:'0.33',
        left:'-19px',       
        top:'-9px',     
        fontFamily: "BrandonGrotesqueWeb-Regular",
    }

   

        let DayPickerStrings: IDatePickerStrings = {
            months: [
              'Januar',
              'Februar',
              'Marts',
              'April',
              'Maj',
              'Juni',
              'Juli',
              'August',
              'September',
              'Oktober',
              'November',
              'December'
            ],                
            shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'],
      
            days: ['Søndag', 'Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lørdag'],
      
            shortDays: ['S', 'M', 'T', 'O', 'T', 'F', 'S'],
      
            goToToday: 'Hop til idag',
            prevMonthAriaLabel: 'Go to previous month',
            nextMonthAriaLabel: 'Go to next month',
            prevYearAriaLabel: 'Go to previous year',
            nextYearAriaLabel: 'Go to next year'
          };
      
          if (GetLanguage() === "en-US") {
      
            DayPickerStrings = {
              months: [
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December'
              ],
      
              shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      
              days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      
              shortDays: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
      
              goToToday: 'Go to today',
              prevMonthAriaLabel: 'Go to previous month',
              nextMonthAriaLabel: 'Go to next month',
              prevYearAriaLabel: 'Go to previous year',
              nextYearAriaLabel: 'Go to next year'
            };
          }
        

        return (      
            <foreignObject x='0' y='40' width="140" height="30" style={box} >        
            <div style={selectDate}>{locals.SelectDate}</div>
                <img style={back} onClick={this.back.bind(this)} src={backArrow} alt='back' />
                <DatePicker style={datepicker}
                
          formatDate={ (date? : Date):string =>{ 
            if(!date) return '';
            return  date.getDate() +"-" + (date.getMonth()+1) +"-" + date.getFullYear() 
            
            }            
          }
            strings={DayPickerStrings}
            minDate={new Date(Date.parse("2018-11-01"))}
            maxDate={new Date(Date.now())}
            firstDayOfWeek={1}
            disableAutoFocus={true}            
            showCloseButton={true}            
            value={this.state.date} onSelectDate={this.changeDate.bind(this)}            
            />                    
            <img style={forward} onClick={this.forward.bind(this)} src={Arrow} alt='forward' />        
            </foreignObject>
        );
    }
}

export default HistoryDatePicker;





