import React from 'react';
import { IPowerBoxProps } from '../PowerBox/PowerBox';
import GasLabel from './GasLabel';
import { GetLanguage } from '../Utility';
import { IStrings, strings } from '../../loc/mystrings';
const locals:IStrings = strings(GetLanguage());

export interface IGasLabelsState {
    data: any[];    
}

class GasLabels extends React.Component<IPowerBoxProps, IGasLabelsState> {    
    constructor(props: IPowerBoxProps,) {
        super(props);        
        this.state = { data : []};
    }   

    componentDidUpdate(prevprops:IPowerBoxProps) {        
        if(this.props && this.props.data && prevprops.data !== this.props.data){
            this.updateLabels();           
        }    
    }
    
        
    componentDidMount() {
        if(this.props && this.props.data){
            this.updateLabels();           
        }                
    }
 
    private updateLabels(){
        let dataArray:any[]=[];      
        let data = this.props.data;

            // dataArray.push({label: locals.DailyGasFromBiomethane, value:formatAbsNumber(data.KWhFromBiogas) + ' kWh', xpos:'155', ypos:'165'});                
            // dataArray.push({label:locals.DailyGasConsumptionInDenmark, value:formatAbsNumber(data.KWhToDenmark)+ ' kWh', xpos:'155', ypos:'135'});                
            dataArray.push({label: locals.DailyGasFromNorway, value:data.KWhFromNorthSea, xpos:'45', ypos:'145'});        
            // dataArray.push({label: data.KWhToOrFromStorage>0?locals.DailyGasToStorage:locals.DailyGasFromStorage , value:formatAbsNumber(data.KWhToOrFromStorage) + ' kWh', xpos:'170', ypos:'75'});                                                
            dataArray.push({label: data.KWhToOrFromGermany>0?locals.DailyGasFromGermany:locals.DailyGasToGermany, value:data.KWhToOrFromGermany , xpos:'115', ypos:'250'});                        
            dataArray.push({label: locals.DailyGasToSweden, value:data.KWhToSweden, xpos:'280', ypos:'165'});                
            dataArray.push({label: locals.DailyGasFromNorthSea, value:data.kWhFromTyra, xpos:'45', ypos:'175'});        
            dataArray.push({label: data.KWhToPoland>0?locals.DailyGasFromPoland:locals.DailyGasToPoland, value:data.KWhToPoland, xpos:'280', ypos:'215'});
                
        this.setState({
            data: dataArray
        });          
    }

    render() {
        let key:number = 1;
        return (      
            this.state && this.state.data.map((itm) => {
             return <GasLabel key={key++} flow={itm.value} label={itm.label} xpos={itm.xpos} ypos={itm.ypos} 
             directionLabel={itm.directionLabel} ekstraLabel={itm.ekstraLabel} ekstraLabelValue={itm.ekstraLabelValue} />
            })
            
        );
    }
}

export default GasLabels;   

