export interface IStrings {
    VikingLink:string;

    GasstorageConsumptionInDK:string;
    PowerDataMissing:string;
    GasDataMissing:string;
    PowerDataError:string;
    GasDataError:string;
    HourlyData:string;

    SelectDate:string;
    DailyGasFromNorway:string;
    DailyGasFromNorthSea:string;
    DailyGasToGermany:string;
    DailyGasFromGermany:string;
    DailyGasConsumptionInDenmark:string;
    DailyGasFromBiomethane:string;
    DailyGasFromStorage:string;
    DailyGasToStorage:string;
    DailyGasToSweden:string;
    DailyGasFromPoland:string;
    DailyGasToPoland:string;

    DailyUpdate:string;
    ReadMoreHeadline:string;
    ReadMoreLink1:string;
    ReadMoreLink2:string;
    ReadMoreLink3:string;
    ReadMoreLink4:string;
    ReadMoreLink1URL:string;
    ReadMoreLink2URL:string;
    ReadMoreLink3URL:string;
    ReadMoreLink4URL:string;
    TheEnergySystemRightNow:string;
    ApplicableFor:string;
    Area:string;
    BornholmSweden:string; 
    Biomethane:string;
    BiomethaneProduction:string;
    CableAc:string;
    CableDc:string;
    CalorificValue:string;
    ChooseDate:string;
    Co2Emissions:string;
    ConsumptionInDenmark:string;
    GasConsumptionInDenmark:string;
    ConverterSubstation:string;
    DisplayMode:string;
    EgtvedCalorificValue:string;
    ElectricityConsumption:string;
    EnergyExchange:string;
    FromGermany:string;
    FromTheNorthSea:string;
    GasPipe:string;
    GasPipeOwnedByOthers:string;
    GasStorage:string;
    Germany:string;
    HistoricalData:string;
    Include:string;
    JutlandGermany:string;
    JutlandNetherlands:string;
    JutlandNorway:string;
    JutlandSweden:string;
    LastUpdate:string;
    LatestProductionDataFromThyra:string;
    LilleTorupGasStorage:string;
    LilleTorupGasStorageShort:string;
    StorageInjection:string;
    StorageWithdrawel:string,
    GasStorageInjection:string;
    StorageProduction:string;    
    Map:string;    
    Measurements:string;
    Mrstation:string;
    NetExchangeImport:string;
    OffshoreWindFarm:string;
    OverheadLinesAc:string;
    OverheadLinesDc:string;
    PointOfTransit:string;
    PowerPlantGt100Mw:string;
    PowerPlantsGt100:string;
    PowerPlantsLt100:string;
    PowerRightNow:string;
    SolarCells:string;
    StenlilleGasStorage:string;
    StenlilleGasStorageShort:string;
    SymbolDescription:string;
    TheElectricityNetRightNow:string;
    TheGasNetRightNow:string;
    TheGreatBelt:string;
    ToSweden:string;
    ToPoland:string;
    ToPolandShort:string;
    FromNorway:string;
    TotalProduction:string;
    WindTurbines:string;
    ZealandGermany:string;
    ZealandSweden:string;     

    Export:string;
    Import:string;    
    BiomethaneConnection:string;
}

let strings = (local:string): IStrings =>  {
    if(local==='en-US') {    
        return  {
            VikingLink:"Jutland - England",

            GasstorageConsumptionInDK:'EXIT FROM TRANSMISSION TO DISTRUBUTION IN DENMARK',
            PowerDataMissing:'The map is currently not displaying the latest data. Latest data is from: [DateTime]. We are working to resolve the issue.',
            GasDataMissing:'The map is currently not displaying the latest data. Latest data is from: [DateTime]. We are working to resolve the issue.',
            PowerDataError:'We are experiencing a data error in the map of the energy system and are working to resolve the issue.',
            GasDataError:'We are experiencing a data error in the map of the energy system and are working to resolve the issue.',
            SelectDate:'Select date',
            DailyGasFromNorway:'FROM NORWAY',
            DailyGasFromNorthSea:'FROM THE NORTH SEA',
            DailyGasToGermany:'TO GERMANY',
            DailyGasFromGermany:'FROM GERMANY',
            DailyGasConsumptionInDenmark:'CONSUMPTION IN DENMARK',
            DailyGasFromBiomethane:'INJECTED BIOMETHANE',
            DailyGasFromStorage:'DANISH GAS STORAGE FACILITIES (out of storage)',
            DailyGasToStorage:'DANISH GAS STORAGE FACILITIES (into storage)',
            DailyGasToSweden:'TO SWEDEN',
            DailyGasFromPoland:'FROM POLAND',
            DailyGasToPoland:'TO POLAND',
            HourlyData:'HOURLY DATA',

            DailyUpdate:'Daily update at 12 noon CET',
            ReadMoreHeadline:'MORE ABOUT GAS QUALITY AND DAILY DATA',
            ReadMoreLink1:'Entry/exit quality and gas flow',
            ReadMoreLink2:'Gas quality and gas flow per MR-station',
            ReadMoreLink3:'Commercial gas amounts',
            ReadMoreLink4:'',
            ReadMoreLink1URL:'https://www.energidataservice.dk/tso-gas/EntryExitGasquality',
            ReadMoreLink2URL:'https://www.energidataservice.dk/tso-gas/MrStationsGasquality',
            ReadMoreLink3URL:'https://www.energidataservice.dk/tso-gas/Gasflow',
            ReadMoreLink4URL:'',
            TheEnergySystemRightNow:'THE ENERGY SYSTEM RIGHT NOW',
            ApplicableFor:'Applicable for',
            Area:'AREA',
            BornholmSweden:'Bornholm - Sweden',
            Biomethane:'BIOMETHANE (Previous day)',
            BiomethaneProduction:'BIOMETHANE PRODUCTION',
            CableAc:'CABLE, AC',
            CableDc:'CABLE, DC',
            CalorificValue:'CALORIFIC VALUE',
            ChooseDate:'Choose date',
            Co2Emissions:'CO2 EMISSIONS',
            ConsumptionInDenmark:'CONSUMPTION IN DENMARK',
            GasConsumptionInDenmark:'CONSUMPTION IN DK (Previous day)',
            ConverterSubstation:'CONVERTER SUBSTATION',
            DisplayMode:'DISPLAY MODE',
            EgtvedCalorificValue:'EGTVED CALORIFIC VALUE',
            ElectricityConsumption:'ELECTRICITY CONSUMPTION',
            EnergyExchange:'Energy exchange',
            FromGermany:'FROM GERMANY',
            FromTheNorthSea:'FROM NORWAY (Previous day)',
            GasPipe:'Gas Pipe',
            GasPipeOwnedByOthers:'Gas pipe owned by others',
            GasStorage:'Gas Storage',
            Germany:'Germany',
            HistoricalData:'Historical data',
            Include:'INCLUDE',
            JutlandGermany:'Jutland - Germany',
            JutlandNetherlands:'Jutland - Netherlands',
            JutlandNorway:'Jutland - Norway',
            JutlandSweden:'Jutland - Sweden',
            LastUpdate:'Last update',
            LatestProductionDataFromThyra:'LATEST PRODUCTION DATA FROM TYRA',
            LilleTorupGasStorage:'LILLE TORUP GAS STORAGE',
            LilleTorupGasStorageShort:'LILLE TORUP',
            StorageInjection:'INJECTION',
            StorageWithdrawel:'WITHDRAWAL',
            GasStorageInjection:'GASSTORAGE INJECTION',            
            StorageProduction:'PRODUCTION',
            Map:'Map',            
            Measurements:'Measurements',
            Mrstation:'M/R-station',
            NetExchangeImport:'NET EXCHANGE IMPORT',
            OffshoreWindFarm:'OFFSHORE WIND FARM',
            OverheadLinesAc:'OVERHEAD LINES, AC',
            OverheadLinesDc:'OVERHEAD LINES, DC',
            PointOfTransit:'Point of transit',
            PowerPlantGt100Mw:'POWER PLANT (>100 MW)',
            PowerPlantsGt100:'POWER PLANTS (> 100 MW)',
            PowerPlantsLt100:'POWER PLANTS (< 100 MW)',
            PowerRightNow:'Power right now',
            SolarCells:'SOLAR CELLS',
            StenlilleGasStorage:'STENLILLE GAS STORAGE',
            StenlilleGasStorageShort:'STENLILLE',
            SymbolDescription:'Symbol description',
            TheElectricityNetRightNow:'The electricity net right now',
            TheGasNetRightNow:'DAILY DATA IN THE GAS SYSTEM',
            TheGreatBelt:'The Great Belt',
            ToSweden:'TO SWEDEN',
            ToPoland:'TO POLAND (Previous day)',
            ToPolandShort:'To Poland',
            FromNorway:'FROM NORWAY (Previous day)',
            TotalProduction:'Total production',
            WindTurbines:'WIND TURBINES',
            ZealandGermany:'Zealand - Germany',   
            ZealandSweden:'Zealand - Sweden',   
            Export:'EXPORT',
            Import:'IMPORT',
            BiomethaneConnection:'BIOMETHANE junction'            
        } 
    } else {
        return {
            VikingLink:"Jylland - England",

            GasstorageConsumptionInDK:'EXIT FROM TRANSMISSION TO DISTRUBUTION IN DENMARK', 
            PowerDataMissing:'Kortet viser i øjeblikket ikke seneste data. De viste data er fra: [DateTime]. Vi arbejder på at udbedre fejlen.',
            GasDataMissing:'Kortet viser i øjeblikket ikke seneste data. De viste data er fra: [DateTime]. Vi arbejder på at udbedre fejlen.',
            PowerDataError:'Der er i øjeblikket fejl i kortet. Vi arbejder på at løse problemet.',
            GasDataError:'Der er i øjeblikket fejl i kortet. Vi arbejder på at løse problemet.',
            SelectDate:'Vælg dato',
            DailyGasFromNorway:'FRA NORGE',
            DailyGasFromNorthSea:'FRA NORDSØEN',
            DailyGasToGermany:'TIL TYSKLAND',
            DailyGasFromGermany:'FRA TYSKLAND',
            DailyGasConsumptionInDenmark:'FORBRUG I DANMARK',
            DailyGasFromBiomethane:'TILFØRT BIOGAS',
            DailyGasFromStorage:'DANSKE GASLAGRE (ud af lager)',
            DailyGasToStorage:'DANSKE GASLAGRE (ind i lager)',
            DailyGasToSweden:'TIL SVERIGE',
            DailyGasFromPoland:'FRA POLEN',
            DailyGasToPoland:'TIL POLEN',
            HourlyData:'HOURLY DATA',
            
            DailyUpdate:'Opdateres dagligt kl. 12:00',
            ReadMoreHeadline:'MERE OM DATA OG GASKVALITET',
            ReadMoreLink1:'Entry/exit gaskvalitet og flow',
            ReadMoreLink2:'Gaskvalitet og flow per MR-station',
            ReadMoreLink3:'Kommercielle gasmængder',
            ReadMoreLink4:'',
            ReadMoreLink1URL:'https://www.energidataservice.dk/tso-gas/EntryExitGasquality',
            ReadMoreLink2URL:'https://www.energidataservice.dk/tso-gas/MrStationsGasquality',
            ReadMoreLink3URL:'https://www.energidataservice.dk/tso-gas/Gasflow',
            ReadMoreLink4URL:'',
            TheEnergySystemRightNow:'ENERGISYSTEMET LIGE NU',
            ApplicableFor:'Gældende for',
            Area:'OMRÅDE',
            BornholmSweden:'Bornholm - sverige',
            Biomethane:'TILFØRT BIOGAS (Dagen før)',
            BiomethaneProduction:'BIOGAS PRODUKTION',
            CableAc:'KABEL, VEKSELSTRØM',
            CableDc:'KABEL, JÆVNSTRØM',
            CalorificValue:'BRÆNDVÆRDI',
            ChooseDate:'Vælg dato',
            Co2Emissions:'CO2-UDLEDNING',
            ConsumptionInDenmark:'FORBRUG I DK',
            GasConsumptionInDenmark:'FORBRUG I DK (Dagen før)',
            ConverterSubstation:'TRANSFORMERSTATION',
            DisplayMode:'VISNING',
            EgtvedCalorificValue:'EGTVED BRÆNDVÆRDI',
            ElectricityConsumption:'ELFORBRUG',
            EnergyExchange:"Energi udveksling",
            FromGermany:'FRA TYSKLAND',
            FromTheNorthSea:'FRA NORGE (Dagen før)',
            GasPipe:'Gasrør',
            GasPipeOwnedByOthers:'Gasrør ejet af andre',
            GasStorage:'Gaslager',
            Germany:'Tyskland',
            HistoricalData:'Historiske Data',
            Include:'INKLUDER',
            JutlandGermany:'Jylland - Tyskland',
            JutlandNetherlands:'Jylland - Holland',
            JutlandNorway:'Jylland - Norge',
            JutlandSweden:'Jylland - Sverige',
            LastUpdate:'Sidst opdateret',
            LatestProductionDataFromThyra:'SENESTE PRODUKTIONSDATA FRA TYRA',
            LilleTorupGasStorage:'LILLE TORUP GASLAGER',
            LilleTorupGasStorageShort:'LILLE TORUP',
            StorageProduction:'UDTRÆK',
            StorageInjection:'INJEKTION',
            StorageWithdrawel:'WITHDRAWAL',
            GasStorageInjection:'GASSTORAGE INJEKTION',
            Map:'Kort',            
            Measurements:'Målinger',
            Mrstation:'M/R station',
            NetExchangeImport:'NETTOUDVEKSLING IMPORT',
            OffshoreWindFarm:'HAVVINDMØLLEPARK',
            OverheadLinesAc:'LUFTLEDNING, VEKSELSTRØM',
            OverheadLinesDc:'LUFTLEDNING, JÆVNSTRØM',
            PointOfTransit:'Transitpunkt',
            PowerPlantGt100Mw:'VÆRK OVER 100 MW',
            PowerPlantsGt100:'STØRRE VÆRKER OVER 100 MW',
            PowerPlantsLt100:'MINDRE ANLÆG UNDER 100 MW',
            PowerRightNow:'Elsystemet lige nu',
            SolarCells:'Solceller',
            StenlilleGasStorage:'STENLILLE GASLAGER',
            StenlilleGasStorageShort:'STENLILLE',
            SymbolDescription:'Ikonforklaring',
            TheElectricityNetRightNow:'Elnettet lige nu',
            TheGasNetRightNow:'DAGLIGE TAL FOR GASNETTET',
            TheGreatBelt:'Storebælt',
            ToSweden:'TIL SVERIGE',
            ToPoland:'TIL POLEN (Dagen før)',
            ToPolandShort:'Til Polen',
            FromNorway:'FRA NORGE (Dagen før)',
            TotalProduction:'Total produktion',
            WindTurbines:'VINDMØLLER',
            ZealandGermany:'Sjælland - Tyskland',  
            ZealandSweden:'Sjælland - Sverige',   
            Export:'EKSPORT',
            Import:'IMPORT',            
            BiomethaneConnection:'Biogas til transmisionsnettet'            
        } 
    }      
}
 
   export {strings}