 const formatRoundNumber =(value:number)  => {
    let num:number = Math.round(value);
    let ret = num.toLocaleString(GetLanguage());
      if(ret === 'NaN')
         ret='-';
    return ret;
 }
 
 const formatNumberReverse=(value:number) => {
    let num:number = Math.round(value)*-1;
    return num.toLocaleString(GetLanguage());
 }
 
 const formatAbsNumber = (value:number) => {
   let num:number = Math.round(value);
   num = Math.abs(num);
   return num.toLocaleString(GetLanguage());
}

const formatAbsNumberMW = (value:number) => {
   let num:number = Math.round(value/1000);
   num = Math.abs(num);
   return num.toLocaleString(GetLanguage());
}


const formatNumber = (value:number) => {        
   if(value)   
       return value.toLocaleString(GetLanguage());
   else
       return '0';
}

const EDSUrl = () => {
   //return './api/';
   //return 'https://energinetkort.azurewebsites.net/api/';
   return portalUrl()+'api/';   
}

const portalUrl = () => {   
   //return 'https://localhost:44377/';
   //return 'https://energinetkort.azurewebsites.net/';
   return 'https://prod-wwwenerginetdk-kort-app.azurewebsites.net/';
   //return 'https://localhost:7237/';
}

//https://energinetkort.azurewebsites.net/api/EDS?cacheName=PowerRightNow&dataset=powersystemrightnow?sort=Minutes1UTC desc_limit=1

//https://energinetkort.azurewebsites.net/api/EDS?dataset=Gasflow?offset=0_start=2023-04-10T00:00_end=2023-04-11T00:00_sort=GasDay
                                            //EDS?dataset=Gasflow?offset=0_start=2023-04-17T00:00_end=2023-04-17T00:00_sort=GasDay
const GetLanguage = () => {      
   if (document.location.search.indexOf('map=gasstorage') !== -1)
      return 'en-US';
   if(document.location.search.indexOf('en-US') !== -1)         
      return 'en-US';

   if(document.location.href.indexOf('/en/') !== -1)         
      return 'en-US';
   
   if(document.location.href.indexOf('en.energinet.dk') !== -1)         
      return 'en-US';

   return 'da-DK';
}

export {formatRoundNumber, formatNumber, formatNumberReverse, formatAbsNumber, formatAbsNumberMW, EDSUrl, GetLanguage, portalUrl};