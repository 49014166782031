import React from 'react';
import BalticPipe from './BalticPipe';
import './GasPipes.css';

export interface IGasPipesState {
    convertToGasStorage: boolean;
    gasData: any;
    gasStorageData: any;
}

export interface IGasPipesProps {
    convertToGasStorage: boolean;
    gasData: any;
    gasStorageData: any;
}

class GasPipes extends React.Component<IGasPipesProps, IGasPipesState> {
    constructor(props: IGasPipesProps) {
        super(props);
        this.state = { convertToGasStorage: this.props.convertToGasStorage, gasData: this.props.gasData, gasStorageData: this.props.gasStorageData };
    }

    componentDidUpdate(prevprops: IGasPipesProps) {
        if (this.props && this.props && prevprops !== this.props) {
            this.setState({ convertToGasStorage: this.props.convertToGasStorage, gasData: this.props.gasData, gasStorageData: this.props.gasStorageData });
        }
    }

    componentDidMount() {
        this.setState({ convertToGasStorage: this.props.convertToGasStorage, gasData: this.props.gasData, gasStorageData: this.props.gasStorageData });
    }

    private getAnimationClass(value: string, reverse?:boolean): string {

        let color = 'gasColor';
        if (this.state?.convertToGasStorage) 
            color = 'gasStorageColor';

        let animationClass: string = color + ' gasjunction-10 gasJunction-noAnimation';
        let intValue = parseInt(value);
        if(reverse)
            intValue=intValue*-1;

        if (intValue > 0)
            animationClass = color + ' gasjunction-10 gasJunction-reverse-animation'
        else if (intValue < 0)
            animationClass = color + ' gasjunction-10 gasJunction-animation'
        return animationClass;
    }
     
    render() {
        var directionNorway: string = "gasjunction-10 gasJunction-reverse-animation";
        var directionNorthSea: string = "gasjunction-10 gasJunction-reverse-animation";
        var directionGermany: string = "gasjunction-10 gasJunction-animation";
        var directionSweeden: string = "gasjunction-10 gasJunction-reverse-animation";        
        if (!this.state?.convertToGasStorage) {
            directionNorway = this.getAnimationClass(this.state?.gasData?.KWhFromNorthSea);
            directionNorthSea = this.getAnimationClass(this.state?.gasData?.kWhFromTyra);
            directionGermany = this.getAnimationClass(this.state?.gasData?.KWhToOrFromGermany);
            directionSweeden = this.getAnimationClass(this.state?.gasData?.KWhToSweden, true);                
        } else {            
            //Danish Import
            directionNorway = this.getAnimationClass(this.state?.gasStorageData?.norwegianImport);
            //Norwegian Import
            directionNorthSea = this.getAnimationClass(this.state?.gasStorageData?.danishImport);
            //German Import
            directionGermany = this.getAnimationClass(this.state?.gasStorageData?.importEllund);
            //Sweeden Import
            directionSweeden = this.getAnimationClass(this.state?.gasStorageData?.exportSweden, true);        
        }

        return (
            <React.Fragment>
                <g id="gasror">
                    <g id="gasror-2" data-name="gasror">
                        <polyline className="gasjunction-9" points="161.8 82.4 156.9 90.4 150.9 102.1 138.3 119.4 134 133.6 129.7 144.8 128.9 156.8 128.3 169.2 128.3 188.1 128.3 198.7 128.3 208.6 128.5 218.7 128.5 232.1 125.2 239.5 128.5 248"></polyline>
                        <polyline className="gasjunction-9" points="84.1 180.6 99.8 183.3 111.3 185.9 128.3 188.1 136.7 188.6 145 188.6 149.4 191.6 154.9 197.4 164.6 200.8 173 205.1 175.3 208.1 178.5 208.1 182.2 205.6 192.5 205.5 213.8 202.7 225.4 202.8 235.3 202.8 248.6 200.9 251.2 192.7 252 182.6 253.4 174.1"></polyline>
                        <polyline className="gasjunction-9" points="129.6 145.6 145.7 143.8 160.6 144.2"></polyline>
                        <line className="gasjunction-9" x1="230.3" y1="191" x2="251.7" y2="190.2"></line>
                        <polyline className="gasjunction-9" points="252 187.6 261.4 186 267.2 187 270 189.86"></polyline>
                    </g>
                    <g id="gasror_ejet_af_andre">
                        {/* Norwegian import */}
                        <line className={directionNorway} x1="0" y1="163" x2="80" y2="176"></line>
                        <line className={directionNorway} x1="80" y1="176" x2="85" y2="179"></line>                        
                        {/* Danish import */}
                        <path id="FromNorway" className={directionNorthSea} d="M-16,164.21l82.1,15.56" transform="translate(16.5 0.5)"></path>
                        {/* ToGermany */}
                        {/* <path id="ToGermany" className={directionGermany} d="M110.4,244.5l47,97.5" transform="translate(20 6)" ></path> */}
                        <line className={directionGermany} x2="130" y2="250" x1="154" y1="300"></line>
                        {/* ToSweeden */}
                        <path id="ToSweeden" className={directionSweeden} d="M255.5,187.36l18.9,0.24L277,179l-2.6-9.9-6.3-16.6,3 -41, -10 -10, -43 -99" transform="translate(18 3.1)" ></path>
                    </g>

                    <BalticPipe KWhToPoland={this.state.gasData.KWhToPoland} convertToGasStorage={this.state?.convertToGasStorage} />

                    <g id="biogasanlaeg" data-name="biogasanlaeg" xlinkHref="#biogasanlaeg">
                        <use width="14" height="14" transform="translate(110 205)" xlinkHref="#biogasanlaeg"></use>
                    </g>

                    {!this.state?.convertToGasStorage &&
                        <g id="junctions">
                            <use width="4" height="4" transform="translate(159 81)" xlinkHref="#junction_02"></use>
                            <use width="4" height="4" transform="translate(154 90)" xlinkHref="#junction_02"></use>
                            <use width="4" height="4" transform="translate(149 100)" xlinkHref="#junction_02"></use>
                            <use width="4" height="4" transform="translate(136.8 116.6)" xlinkHref="#junction_02"></use>
                            <use width="4" height="4" transform="translate(133 128)" xlinkHref="#junction_02"></use>
                            <use width="4" height="4" transform="translate(130.5 136)" xlinkHref="#junction_02"></use>
                            <use width="4" height="4" transform="translate(127.6 143.6)" xlinkHref="#junction_02"></use>
                            <use width="4" height="4" transform="translate(138.81 142.3)" xlinkHref="#junction_02"></use>
                            <use width="4" height="4" transform="translate(126.98 155.19)" xlinkHref="#junction_02"></use>
                            <use width="4" height="4" transform="translate(126.36 167.18)" xlinkHref="#junction_02"></use>
                            <use width="4" height="4" transform="translate(126.36 186.12)" xlinkHref="#centralt_kraftvaerk-2"></use>

                            <use width="4" height="4" transform="translate(143.5 187.14)" xlinkHref="#junction_02"></use>
                            <use width="4" height="4" transform="translate(144.08 192.7)" xlinkHref="#junction_02"></use>
                            <use width="4" height="4" transform="translate(126.37 198)" xlinkHref="#junction_02"></use>
                            <use width="4" height="4" transform="translate(126.37 211.82)" xlinkHref="#junction_02"></use>

                            <use width="4" height="4" transform="translate(123.3 237.4)" xlinkHref="#junction_02"></use>
                            <use width="4" height="4" transform="translate(126.37 193.67)" xlinkHref="#junction_02"></use>
                            <use width="4" height="4" transform="translate(147.36 190.59)" xlinkHref="#junction_02"></use>
                            <use width="4" height="4" transform="translate(150.56 193.17)" xlinkHref="#junction_02"></use>
                            <use width="4" height="4" transform="translate(162.37 198.67)" xlinkHref="#junction_02"></use>
                            <use width="4" height="4" transform="translate(167.37 201.2)" xlinkHref="#junction_02"></use>
                            <use width="4" height="4" transform="translate(180.37 203.54)" xlinkHref="#junction_02"></use>
                            <use width="4" height="4" transform="translate(185.61 203.54)" xlinkHref="#junction_02"></use>
                            <use width="4" height="4" transform="translate(190.85 203.54)" xlinkHref="#junction_02"></use>
                            <use width="4" height="4" transform="translate(213.84 200.83)" xlinkHref="#junction_02"></use>
                            <use width="4" height="4" transform="translate(223.36 200.68)" xlinkHref="#junction_02"></use>
                            <use width="4" height="4" transform="translate(233.37 200.83)" xlinkHref="#junction_02"></use>
                            <use width="4" height="4" transform="translate(246.53 198.83)" xlinkHref="#junction_02"></use>
                            <use width="4" height="4" transform="translate(249.2 190.7)" xlinkHref="#junction_02"></use>
                            <use width="4" height="4" transform="translate(250 185.61)" xlinkHref="#junction_02"></use>
                            <use width="4" height="4" transform="translate(250 179.55)" xlinkHref="#junction_02"></use>
                            <use width="4" height="4" transform="translate(250.87 174.21)" xlinkHref="#junction_02"></use>
                            <use width="4" height="4" transform="translate(255.52 185)" xlinkHref="#junction_02"></use>
                            <use width="4" height="4" transform="translate(258.5 184.7)" xlinkHref="#junction_02"></use>
                            <use width="4" height="4" transform="translate(265 185.3)" xlinkHref="#junction_02"></use>
                            <use width="4" height="4" transform="translate(265 177.32)" xlinkHref="#junction_02"></use>
                            <use width="4" height="4" transform="translate(264.8 181)" xlinkHref="#junction_02"></use>
                            <use width="4" height="4" transform="translate(261.41 184.68)" xlinkHref="#junction_02"></use>
                            <use width="4" height="4" transform="translate(97.34 181.17)" xlinkHref="#junction_02"></use>

                            <use width="4" height="4" transform="translate(126.7 245.83)" xlinkHref="#junction"></use>
                            <use width="4" height="4" transform="translate(88.1 179.7)" xlinkHref="#junction"></use>
                            <use width="4" height="4" transform="translate(268.3 188.3)" xlinkHref="#junction"></use>
                        </g>
                    }
                </g>
            </React.Fragment>
        );
    }
}

export default GasPipes;