import React, { Fragment } from 'react';
import { IStrings, strings } from '../../loc/mystrings';
import { GetLanguage } from '../Utility';
import './ReadMore.css';
export interface IReadMoreProps {
    date?:any;
}

export interface IReadMoreState {
    date?:any;
}

const locals:IStrings = strings(GetLanguage());

class ReadMore extends React.Component {                     
    render() {                               
        return (      
            <div id="readmorebox">
            <div className="map-sidebar-box">
                <h3>{ locals.ReadMoreHeadline } </h3>                 
                <ul className="no-bullet">                        
                    <li><span className="title"> <a href={ locals.ReadMoreLink1URL } className="whiteLink" >{ locals.ReadMoreLink1 }</a></span></li>
                    <li><span className="title"> <a href={ locals.ReadMoreLink2URL } className="whiteLink" >{ locals.ReadMoreLink2 }</a></span></li>
                    <li><span className="title"> <a href={ locals.ReadMoreLink3URL } className="whiteLink" >{ locals.ReadMoreLink3 }</a></span></li>
                    {/* <li><span className="title"> <a href={ locals.ReadMoreLink4URL } className="whiteLink" >{ locals.ReadMoreLink4 }</a></span></li> */}
                   
                </ul>                                    
            </div>
        </div>          
        );
    }
}

export default ReadMore;





