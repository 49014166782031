import React from 'react';
import './PowerplantSymbol.css';

class PowerplantSymbol extends React.Component<{}, {}> {        
    render() {                
        return ( 
            <React.Fragment>
            <symbol id="powerplant" data-name="powerplant" viewBox="0 0 14.99 14.99">
                <rect className="rectangle" x="1.5" y="1.5" width="11.99" height="11.99" />
            </symbol>          
        </React.Fragment>
         );
    }
}
 
export default PowerplantSymbol;