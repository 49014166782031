import React from 'react';
import { IPowerBoxProps } from '../PowerBox/PowerBox';
import './Cabels.css';
import { get } from 'http';

export interface ICabelsState {
    DK2_DE?: string;
    DK1_DK2?: string;
    DK1_NO?: string;
    DK1_SE?: string;
    DK1_NL?: string;
    DK1_DE?: string;
    DK2_SE?: string;
    BH_SE?: string;
    DK1_GB?: string;
    Minutes1DK?: string;
}

class Cabels extends React.Component<IPowerBoxProps, ICabelsState> {

    componentDidMount() {
        if (this.props && this.props.data) {
            this.setCabels();
        }
    }

    componentDidUpdate(prevprops: IPowerBoxProps) {        
        if (this.props && this.props.data && prevprops.data !== this.props.data) {
            this.setCabels();
        }
    }

    private getAnimationClass(value: string, reverse?: boolean): string {

        let animationClass: string = 'noAnimation';
        let intValue = parseInt(value);
        if (reverse)
            intValue = intValue * -1;

        if (intValue > 0)
            animationClass = 'reverse-animation'
        else if (intValue < 0)
            animationClass = 'animation'
        return animationClass;
    }

    private setCabels() {
        let elm = this.props.data;        
        let dk1_no: string = this.getAnimationClass(elm["Exchange_DK1_NO"]);
        let dk1_se: string = this.getAnimationClass(elm["Exchange_DK1_SE"]);
        let dk1_nl: string = this.getAnimationClass(elm["Exchange_DK1_NL"]);
        let dk1_de: string = this.getAnimationClass(elm["Exchange_DK1_DE"]);
        let dk1_dk2: string = this.getAnimationClass(elm["Exchange_DK1_DK2"]);
        let dk2_se: string = this.getAnimationClass(elm["Exchange_DK2_SE"]);
        let dk2_de: string = this.getAnimationClass(elm["Exchange_DK2_DE"], true);
        let bh_se: string = this.getAnimationClass(elm["Exchange_Bornholm_SE"]);
        let dk1_gb: string = this.getAnimationClass(elm["Exchange_DK1_GB"]);

        this.setState({
            DK2_DE: dk2_de,
            DK1_DK2: dk1_dk2,
            DK1_NO: dk1_no,
            DK1_SE: dk1_se,
            DK1_NL: dk1_nl,
            DK1_DE: dk1_de,
            DK2_SE: dk2_se,
            BH_SE: bh_se,
            DK1_GB: dk1_gb,
            Minutes1DK: this.props.data.Minutes1DK
        });
    }

    render() {
        return (
            <React.Fragment>
                <g id="dc-cable">
                    {/* Sjælland -> Tyskland  */}
                    <g id="power-5" className={this.state?.DK2_DE}>
                        <line className="cls-13" x1="239.2" y1="198.1" x2="239.28" y2="198.59"></line>
                        <polyline className="cls-14" points="239.93 202.5 240.1 203.5 240.1 225.9 237.6 238 241.2 247.6 240.6 259.4 232.3 292.69"></polyline>
                        <line className="cls-13" x1="231.82" y1="294.61" x2="231.7" y2="295.1"></line>
                    </g>
                    {/* Fyn -> Sjælland */}
                    <g id="power-3" className={this.state?.DK1_DK2}>
                        {/* <line className="cls-13" x1="178.5" y1="208.1" x2="178.95" y2="207.89"></line> */}
                        <line className="cls-13" x2="182.72" y2="206.14" x1="213.76" y1="191.69"></line>
                        {/* <line className="cls-13" x1="215.65" y1="190.81" x2="216.1" y2="190.6"></line> */}
                    </g>
                    {/* jylland -> Norge */}
                    <g id="power-1" className={this.state?.DK1_NO}>
                        <line className="cls-13" x1="74.13" y1="0.5" x2="74.4" y2="0.92"></line>
                        <line className="cls-16" x1="76.58" y1="4.3" x2="119.23" y2="70.29"></line>
                        <line className="cls-13" x1="120.33" y1="71.98" x2="120.6" y2="72.4"></line>
                    </g>
                    {/* Jylland -> Sverige */}
                    <g id="power-2" className={this.state?.DK1_SE}>
                        <line className="cls-13" x2="176.7" y2="75.3" x1="177.16" y1="75.1"></line>
                        <line className="cls-17" x2="180.91" y2="73.42" x1="240.04" y1="46.96"></line>
                        <line className="cls-13" x2="241.92" y2="46.12" x1="242.38" y1="45.91"></line>
                    </g>
                    {/* Jylland -> Holland */}
                    <g id="power-8" className={this.state?.DK1_NL}>
                        <line className="cls-17" x1="0" y1="273.42" x2="100.5" y2="199.5"></line>
                    </g>
                    {/* Jylland -> England */}
                    <g id="power-9" className={this.state?.DK1_GB}>
                        <line className="cls-17" x1="85" y1="180" x2="119" y2="198"></line>
                        <line className="cls-17" x1="0" y1="233" x2="85" y2="180"></line>
                    </g>
                </g>
                {/* Luftledning, jævnstrøm */}
                <g id="dc-overhead">
                    <polyline className="cls-18" points="120.7 72.4 128.8 84.9 144 124.8"></polyline>
                    <line className="cls-18" x1="163.8" y1="76.4" x2="176.7" y2="75.3"></line>
                </g>
                <g id="ac-cable">
                    {/* Jylland -> Tyskland */}
                    <g id="power-7" className={this.state?.DK1_DE}>
                        {/* <path className="cls-11" d="M110.4,244.5l47,97.5" transform="translate(12.5 9)"></path> */}
                        <line className="cls-11" x2="122" y2="250.1" x1="145" y1="300"></line>
                    </g>
                    {/* Bornholm -> Sverige */}
                    <g id="power-4" className={this.state?.BH_SE}><path className="cls-11" d="M322.8,199.8l18.7,16.6" transform="translate(16.5 0.5)"></path></g>
                    {/* Sjælland -> Sverige */}
                    <g id="power-6" className={this.state?.DK2_SE}><path className="cls-11" d="M252.6,149.5l-3.2,3.66" transform="translate(16.5 0.5)"></path></g>
                    {/* de 2 små på sjælland */}
                    <g id="power-8" className="reverse-animation" ><path className="cls-11" transform="translate(6.5 0.5)" d="M251,188.5l-3.2,1.66"></path></g>
                    <g id="power-9" className="reverse-animation" ><path className="cls-11" transform="translate(8 4)" d="M253,178.5l-5.2,0"></path></g>
                    {/* Sjælland -> Tyskland */}
                    <g id="power-5" className={this.state?.DK2_DE}>
                        <line className="cls-11" x1="243" y1="200" x2="287" y2="230"></line>
                        <line className="cls-11" x1="287" y1="230" x2="245" y2="292.69"></line>
                    </g>
                </g>
            </React.Fragment>
        );
    }
}

export default Cabels;