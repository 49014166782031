import React from 'react';
import './PowerBox.css';
import { formatRoundNumber, GetLanguage } from '../Utility';
import { IStrings, strings } from '../../loc/mystrings';

export interface IPowerBoxProps {
    data?: any;
    dataState?:string;
    // {
    // ProductionGe100MW:number;
    // ProductionLt100MW:number;
    // WindMillTotal:number;
    // SolarPower:number;
    // Exchange_Sum:number;
    // PowerTotal:number;
    // CO2Emission:number;
    // ["Exchange_DK1_DE"]:string;
    // ["Exchange_DK1_SE"]:string;
    // ["Exchange_DK1_NL"]:string;
    // ["Exchange_DK1_DE"]:string;
    // ["Exchange_DK1_DK2"]:string;
    // ["Exchange_DK2_SE"]:string;
    // ["Exchange_DK2_DE"]:string;
    // ["Exchange_Bornholm_SE"]:string;
    // }
}



export interface IPowerBoxState {
    data?: {
    ProductionGe100MW:number;
    ProductionLt100MW:number;    
    OnshoreWindPower:number;
    OffshoreWindPower:number;
    SolarPower:number;
    Exchange_Sum:number;
    PowerTotal:number;
    CO2Emission:number;    
    }
}


const locals:IStrings = strings(GetLanguage());

class PowerBox extends React.Component<IPowerBoxProps, IPowerBoxState> {
    constructor(props: IPowerBoxProps) {
        super(props);        
        var d:any = {};                
        this.state = { data : d};
    }    

    componentDidUpdate(prevprops:IPowerBoxProps) {               
        if(this.props && this.props.data && prevprops.data !== this.props.data)
        {
            var d:any = this.props.data;        
            d.PowerTotal = this.props.data.OnshoreWindPower + this.props.data.OffshoreWindPower + 
            this.props.data.SolarPower + this.props.data.ProductionGe100MW + this.props.data.ProductionLt100MW + this.props.data.Exchange_Sum;
        this.setState({ 
            data : d            
        });                
        }
    }
 
//  private formatRoundNumber(value:number) {
//     let num:number = Math.round(value);
//     return num.toLocaleString();
//  }
            
    render() {            
        return (             
            <div id="powerBox">
                <div className="map-sidebar-box">
                    <h3>{ locals.PowerRightNow } </h3> {/* Elsystemet lige nu */}
                    {this.state.data && 
                    <ul className="no-bullet">                        
                        <li><span className="title">{ locals.PowerPlantsGt100 }</span><span className="amount">{formatRoundNumber(this.state.data.ProductionGe100MW)} MW</span></li>
                        <li><span className="title"> { locals.PowerPlantsLt100 }</span><span className="amount">{formatRoundNumber(this.state.data.ProductionLt100MW)} MW</span></li>
                        <li><span className="title"> { locals.WindTurbines }</span><span className="amount">{formatRoundNumber(this.state.data.OffshoreWindPower + this.state.data.OnshoreWindPower)} MW</span></li>
                        <li><span className="title"> { locals.SolarCells }</span><span className="amount">{formatRoundNumber(this.state.data.SolarPower)} MW</span></li>
                        <li><span className="title"> { locals.ConsumptionInDenmark }</span><span className="amount">{formatRoundNumber(this.state.data.PowerTotal)} MW</span></li>
                        <li><span className="title"> { locals.NetExchangeImport }</span><span className="amount">{formatRoundNumber(this.state.data.Exchange_Sum)} MW</span></li>                        
                        <li><span className="title"> { locals.Co2Emissions }</span><span className="amount">{formatRoundNumber(this.state.data.CO2Emission)} g/kWh</span></li>                        
                    </ul>                    
                    }
                </div>
            </div>            
         );
    }    
}
 
export default PowerBox;