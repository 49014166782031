import { loadTheme } from '@fluentui/react/lib/Styling';

export function loadEnerginetTheme():any{           
    loadTheme({
    palette: {
        themePrimary: '#02525e',
        themeLighterAlt: '#f1fbfa',
        themeLighter: '#caf1eb',
        themeLight: '#a0e4da',
        themeTertiary: '#51c9b7',
        themeSecondary: '#15b099',
        themeDarkAlt: '#00957f',
        themeDark: '#007e6b',
        themeDarker: '#005d4f',
        neutralLighterAlt: '#f8f8f8',
        neutralLighter: '#f4f4f4',
        neutralLight: '#eaeaea',
        neutralQuaternaryAlt: '#dadada',
        neutralQuaternary: '#d0d0d0',
        neutralTertiaryAlt: '#c8c8c8',
        neutralTertiary: '#8abec8',
        neutralSecondary: '#6cabb6',
        neutralPrimaryAlt: '#5197a4',
        neutralPrimary: '#09505d',
        neutralDark: '#267280',
        black: '#16616e',
        white: '#ffffff',
    }
    });
}