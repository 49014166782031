import React from 'react';
import { formatAbsNumber, formatNumber, formatNumberReverse, GetLanguage } from '../Utility';
import './GasBox.css';

import { IStrings, strings } from '../../loc/mystrings';
const locals:IStrings = strings(GetLanguage());

export interface IGasBoxProps {
    data?: any;
}

export interface IGasBoxState {    
    data?: any;
    //{
        // Biogas_Flow:number;
        // CO2Emission:number;
        // Dragoer_Flow:number;
        // Dragoer_GCV:number;
        // Ellund_Flow:number;
        // ExitZone_Flow:number;
        // LTLager_Flow:number;
        // Nybro_Flow:number;
        // SLLager_Flow:number;
        // SLLager_GCV:number;
        // TYRA_Flow:number;      
    //}
}

class GasBox extends React.Component<IGasBoxProps, IGasBoxState> {
    constructor(props: IGasBoxProps) {
        super(props);                
        this.state = { data : []};
    }    

    // componentDidMount() {                     
    //     this.setState({
    //         data: this.props.data
    //     });                     
    // }

    componentDidUpdate(prevprops:IGasBoxProps) {               
        if(this.props && this.props.data && prevprops.data !== this.props.data)
        {
            //console.log(this.props.data);

            
        
        this.setState({ 
            data : this.props.data
        });                
    }
    }

            
    render() {            
        let lilleTorupLabel=locals.LilleTorupGasStorageShort;
        if(this.state.data && this.state.data.LTLager_Flow !== 0)            
            this.state.data.LTLager_Flow>0? lilleTorupLabel= locals.LilleTorupGasStorageShort +" " + locals.StorageProduction: lilleTorupLabel= locals.LilleTorupGasStorageShort +" " + locals.StorageInjection;
        else
            lilleTorupLabel= locals.LilleTorupGasStorageShort;

        let stenLilleLabel=locals.StenlilleGasStorageShort;
        if(this.state.data && this.state.data.SLLager_Flow !== 0)            
            this.state.data.SLLager_Flow>0?stenLilleLabel= locals.StenlilleGasStorageShort +" " + locals.StorageProduction:stenLilleLabel= locals.StenlilleGasStorageShort +" " + locals.StorageInjection;
        else
            stenLilleLabel= locals.StenlilleGasStorageShort;
        
        let FromTheNorthSeaValue = this.state.data?.GasFlow?.KWhFromNorthSea;
        if(FromTheNorthSeaValue < 0)
            FromTheNorthSeaValue=0
        
            
       let KWhToPoland = this.state.data?.GasFlow?.KWhToPoland;       
       if(KWhToPoland !== 0)
        KWhToPoland = formatNumberReverse(KWhToPoland);


        return (             
            <div id="gasBox">
                <div className="map-sidebar-box">
                    <h3>{locals.TheGasNetRightNow}</h3>
                    {this.state?.data && 
                        <ul className="no-bullet">
                            <li><span className="title">{locals.DailyGasFromBiomethane}</span><span className="amount">{formatNumber(this.state.data?.KWhFromBiogas)} kWh</span></li>
                            <li><span className="title">{locals.DailyGasFromNorway}</span><span className="amount">{formatNumber(this.state.data?.KWhFromNorthSea)} kWh</span></li>                            
                            <li><span className="title">{locals.DailyGasFromNorthSea}</span><span className="amount">{formatNumber(this.state.data?.kWhFromTyra)} kWh</span></li>                            
                            <li><span className="title">{this.state.data?.KWhToOrFromGermany>0?locals.DailyGasFromGermany:locals.DailyGasToGermany}</span><span className="amount">{formatAbsNumber(this.state.data?.KWhToOrFromGermany)} kWh</span></li>                            
                            <li><span className="title">{this.state.data?.KWhToPoland>0?locals.DailyGasFromPoland:locals.DailyGasToPoland}</span><span className="amount">{formatAbsNumber(this.state.data?.KWhToPoland)} kWh</span></li>                            
                            <li><span className="title">{locals.DailyGasToSweden}</span><span className="amount">{formatAbsNumber(this.state.data?.KWhToSweden)} kWh</span></li>
                            <li><span className="title">{this.state.data?.KWhToOrFromStorage>0?locals.DailyGasFromStorage:locals.DailyGasToStorage}</span><span className="amount">{formatAbsNumber(this.state.data?.KWhToOrFromStorage)} kWh</span></li>                            
                            <li><span className="title">{locals.DailyGasConsumptionInDenmark}</span><span className="amount">{formatAbsNumber(this.state.data?.KWhToDenmark)} kWh</span></li>                            
                        </ul>                
                    }
                </div>
            </div>            
         );
    }    
}
 
export default GasBox;