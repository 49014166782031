import React from 'react';
import './GasPipes.css';

export interface IGasPipesState {    
    KWhToPoland: any;
    convertToGasStorage: boolean;
}

export interface IGasPipesProps {    
    KWhToPoland: any;
    convertToGasStorage: boolean;
}

class BalticPipe extends React.Component<IGasPipesProps, IGasPipesState> {
    constructor(props: IGasPipesProps) {
        super(props);
        this.state = {convertToGasStorage: this.props.convertToGasStorage,  KWhToPoland: this.props.KWhToPoland }
    }

    componentDidMount() {
        this.setState(this.props);
    }

    componentDidUpdate(prevprops: IGasPipesProps) {
        if (this.props && this.props && prevprops !== this.props) {            
            this.setState({
                KWhToPoland:   this.props.KWhToPoland             
            });            
        }
    }

    private getAnimationClass(value: string): string {
        let color = 'gasColor';
        if (this.state?.convertToGasStorage) 
            color = 'gasStorageColor';

        let animationClass: string = color + ' gasjunction-10 gasJunction-reverse-noAnimation';
        if (parseInt(value) > 0)
            animationClass = color + ' gasjunction-10 gasJunction-reverse-animation'
        else if (parseInt(value) < 0)
            animationClass = color + ' gasjunction-10 gasJunction-animation'
        return animationClass;
    }    
    

    render() {
        // var directionBalticPipe: string =  "gasjunction-10 gasJunction-animation";
        // if (this.state.KWhToPoland > 0)
        //     directionBalticPipe = "gasjunction-10 gasJunction-reverse-animation";
        let directionBalticPipeClass: string = this.getAnimationClass(this.state.KWhToPoland);
        return (
            <React.Fragment>
                <g id="balticPipe">
                    {/* <path className="gasjunction-10 gasJunction-animation" d="M80.26,180.56l-2.9-5-95-15.56" transform="translate(16.5 0.5)" ></path> */}
                    <path className={directionBalticPipeClass} d="M370,299c-5.9-7.2-17.5-27.2-19.7-38.4-1.298149-6.608759-.312789-18.583965-1.5-22.4-1.4-4.5-8.5-5.6-14.1-9.3-2.891426-1.910407-30.872701-2.934053-43.3-4.1-6.57848-.617202-16.777102.796535-23.8-.5-6.5-1.2-11.457565-1.737038-16.4-2.2-7.9-.74-23.7-6.525187-23.7-6.525187" transform="translate(16.5 0.5)" />
                    <path className="gasjunction-9" d="M198.2,203.5c6,1,2.071602,2.221,6.7,2.2s3.042008.854087,6,2.2s5.230397,5.865606,8,7.2s7.114287.822184,9.6,0s0,0,0,0" transform="translate(16.5 0.5)"/>
                    <path className="gasjunction-9" d="M112,188.7c0,0,2.167103,5.001611,3.8,6.9s3.73344,4.808362,7,5.36s12.6-4.16,11.6-2.16" transform="translate(16.5 0.5)" />
                    <polyline className="gasjunction-9" points="192 206.7 215 204"></polyline>
                    <path className="gasjunction-9" d="M133.6,198.2c0,0,2.111652,1.061571,4.7,2.7s8.571963,7.494632,12.32,8.56s8.342422.400708,11.159256.214813s5.513938-.86222,7.620744-1.414813s6.3-2.36,6.3-2.36" transform="translate(16.5 0.5)" />

                    
                </g>            
            </React.Fragment>
        );
    }
}

export default BalticPipe;