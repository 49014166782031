import React from 'react';
import { formatAbsNumber } from '../Utility';
export interface IGasLabelProps {
    label: string;
    directionLabel: string;
    flow: number;
    ekstraLabel: string;
    ekstraLabelValue: string;
    xpos: string;
    ypos: string
}

export interface IGasLabelState {
    fontSize: string;
}

//const fontSize:string = '.2300rem';

class GasLabel extends React.Component<IGasLabelProps, IGasLabelState> {
    constructor(props: IGasLabelProps,) {
        super(props);
        this.state = { fontSize: '.2300rem' };
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    private updateDimensions = () => {
        if (window.innerWidth < 1024 && window.innerWidth > 640)
            this.setState({ fontSize: (0.2300 * (1 + ((1024 - window.innerWidth) / 500)) + 'rem') });
        else
            this.setState({ fontSize: '0.2300rem' });
    };

    render() {
        let style: React.CSSProperties | object = {
            display: 'inline-block',
            padding: '4px',
            // textTransform: 'uppercase',
            color: '#02525e',
            border: 'solid 1px rgba(255,255,255,0.9)',
            backgroundColor: 'rgba(230,230,230,0.9)',
        }

        let titleStyle: React.CSSProperties | object = {
            fontSize: this.state.fontSize,
            fontFamily: "DINNextLTPro-Medium",
        }

        let ekstratitleStyle: React.CSSProperties | object = {
            fontSize: this.state.fontSize,
            fontFamily: "DINNextLTPro-Medium",
            paddingTop: "3px"
        }

        let valueStyle: React.CSSProperties | object = {
            fontSize: this.state.fontSize,
            fontFamily: "DINNextLTPro-Regular",
            paddingTop: "0px",
            marginTop: "0px",
            textTransform: 'none',
        }
                
        return (
            <foreignObject className="HideOnSmallScreen" x={this.props.xpos} y={this.props.ypos} width="200" height="40">
                <div style={style}>
                    {this.props.label !== undefined &&
                        <div style={titleStyle}>{this.props.label}</div>
                    }
                    {this.props.flow !== undefined &&
                        <div style={valueStyle}>{ formatAbsNumber(this.props.flow)} kWh </div>
                    }
                    {this.props.ekstraLabel !== undefined &&
                        <div style={ekstratitleStyle}>{this.props.ekstraLabel}</div>}
                    {this.props.ekstraLabelValue !== undefined &&
                        <div style={valueStyle}>{this.props.ekstraLabelValue} kWh/m<sup>3</sup></div>
                    }

                </div>
            </foreignObject>
        );
    }
}

export default GasLabel;

// display: inline-block;    
// font-size: .75rem;
// line-height: 1.3;
// position: absolute;
// padding: 10px 12px 0 12px;
// transition: opacity 400ms ease-in-out;
// text-transform: uppercase;
// color: #02525e;
// border: solid 2px rgba(255,255,255,0.9);
// background-color: rgba(230,230,230,0.9);    

