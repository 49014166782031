import React from 'react';
export interface WindmillProps {
    posx:string;
    posy:string;
}
  
class Windmill extends React.Component<WindmillProps, {}> {  
    render() {        
        let transformData:string = `translate(${this.props.posx} ${this.props.posy})`
        return ( 
            <use width="20" height="22.8" transform={transformData} xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#windmill"></use>
         );
    }
}
 
export default Windmill;