import React from 'react';
import logo from '../../images/EnerginetLogo.png';
import { portalUrl } from '../../components/Utility';

export interface IEnerginetLogoProps {
    posx:string;
    posy:string
}

class EnerginetLogo extends React.Component<IEnerginetLogoProps, {}> {    
    render() {        
        var logoUrl:string = portalUrl() + logo;
        return (      
            <foreignObject x={this.props.posx} y={this.props.posy} width="200" height="20">                        
                <div>
                   <img src={logoUrl} width="53px" alt='Energinet logo'/>
                </div>                            
            </foreignObject>
        );
    }
}

export default EnerginetLogo;