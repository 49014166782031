import React from 'react';
import { formatAbsNumberMW } from '../Utility';
export interface IGasStorageLabelProps {
    label: string;
    flow: number;
    xpos: string;
    ypos: string
}

export interface IGasStorageLabelState {
    //fontSize: string;    fontSize: '.2300rem', 
    label: string;
    flow: number;
}

const fontSize:string = '.3300rem';

class GasStorageLabel extends React.Component<IGasStorageLabelProps, IGasStorageLabelState> {
    constructor(props: IGasStorageLabelProps,) {
        super(props);
        this.state = {
            label: props.label,
            flow: props.flow
        };
    }

    // componentDidMount() {
    //     window.addEventListener('resize', this.updateDimensions);
    // }
    // componentWillUnmount() {
    //     window.removeEventListener('resize', this.updateDimensions);
    // }

    componentDidUpdate(prevprops: IGasStorageLabelProps) {
        if (this.props && this.props && prevprops !== this.props) {
            this.setState({
                label: this.props.label,
                flow: this.props.flow
            });
        }
    }

    // private updateDimensions = () => {
    //     if (window.innerWidth < 1024 && window.innerWidth > 640)
    //         this.setState({ fontSize: (0.2300 * (1 + ((1024 - window.innerWidth) / 500)) + 'rem') });
    //     else
    //         this.setState({ fontSize: '0.2300rem' });
    // };

    render() {
        let style: React.CSSProperties | object = {
            display: 'inline-block',
            padding: '4px',
            color: '#415171'
        }

        let titleStyle: React.CSSProperties | object = {
            fontSize: fontSize,
            fontFamily: "BrandonGrotesqueWeb-Bold",
        }

        let valueStyle: React.CSSProperties | object = {
            fontSize: fontSize,
            fontFamily: "BrandonGrotesqueWeb-Regular",
            paddingTop: "0px",
            marginTop: "0px",
            textTransform: 'none',
        }

        return (
            <foreignObject x={this.props.xpos} y={this.props.ypos} width="200" height="40">
                <div style={style}>
                    <div style={titleStyle}>{this.state.label}</div>
                    <div style={valueStyle}>{formatAbsNumberMW(this.state.flow)} MW</div>
                </div>
            </foreignObject>
        );
    }
}

export default GasStorageLabel;
