import React from 'react';
import { formatAbsNumberMW, GetLanguage } from '../Utility';
import './GasStorageLegend.css';
import gasStorageAni from '../../images/GasStorageAnimation.gif';

import { IStrings, strings } from '../../loc/mystrings';
const locals: IStrings = strings(GetLanguage());

export interface IGasStorageLegendProps {
    data?: any;
}

export interface IGasStorageLegendState {
    data?: any;
}

class GasStorageLegend extends React.Component<IGasStorageLegendProps, IGasStorageLegendState> {
    constructor(props: IGasStorageLegendProps) {
        super(props);
        this.state = { data: [] };
    }

    componentDidUpdate(prevprops: IGasStorageLegendProps) {
        if (this.props && this.props.data && prevprops.data !== this.props.data) {            
            this.setState({
                data: this.props.data
            });
        }
    }

    componentDidMount() {
        if(this.props && this.props.data){
            this.setState({
                data: this.props.data
            });      
        }                
    }

    render() {
        return (
            <div className="left-sidebar" >
                <img className="gasStorageAnimation" src={gasStorageAni} alt='Gasstorage animation' />
                <div className="box statistic">
                     <div className="box-line">
                        <div className="box-headline">{locals.HourlyData}</div>                        
                    </div>
                    <div className="box-line stat-image">
                        <div className="box-title">{locals.GasStorage} { (this.state?.data?.gsdStorage>=0)?locals.StorageWithdrawel:locals.StorageInjection}</div>
                        {/* <div id="" className="box-title">{locals.StorageInjection}</div> */}
                        <div id="GasStorage">{formatAbsNumberMW(this.state?.data?.gsdStorage)} MW</div>
                    </div>
                   
                    <div className="box-line">
                        <div className="box-title">{locals.GasstorageConsumptionInDK}</div>
                        <div id="ConsumptionInDK">{formatAbsNumberMW(this.state?.data?.exitFromTransmission)} MW</div>
                    </div>
                </div>
            </div>
        );
    }
}
export default GasStorageLegend;