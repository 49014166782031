import React from 'react';
export interface PowerLabelProps {
    label:string;
    valueLabel:string;
    flow:string;
    xpos:string;
    ypos:string
}


class ExternalPowerLabel extends React.Component<PowerLabelProps, {}> {    
    render() {        
        return (                  
            <li>
                <span className="title">{this.props.label}</span>
                <span className="amount">{this.props.valueLabel}{this.props.flow} MW</span>
            </li>
            
        );
    }
}

export default ExternalPowerLabel;

// display: inline-block;    
// font-size: .75rem;
// line-height: 1.3;
// position: absolute;
// padding: 10px 12px 0 12px;
// transition: opacity 400ms ease-in-out;
// text-transform: uppercase;
// color: #02525e;
// border: solid 2px rgba(255,255,255,0.9);
// background-color: rgba(230,230,230,0.9);    

