import { LabelBase } from '@fluentui/react';
import React from 'react';
import { IStrings, strings } from '../../loc/mystrings';
import { IPowerBoxProps } from '../PowerBox/PowerBox';
import { formatAbsNumber, GetLanguage } from '../Utility';
import ExternalPowerLabel from './ExternalPowerLabel';
import './ExternalPowerLabel.css';

export interface IPowerLabelsState {
    data: any[];    
}

const locals:IStrings = strings(GetLanguage());

class ExternalPowerLabels extends React.Component<IPowerBoxProps, IPowerLabelsState> {    
    constructor(props: IPowerBoxProps,) {
        super(props);        
        this.state = { data : []};
    }   

    componentDidMount(){
        if(this.props && this.props.data){
            this.updateLabels();           
        }
    }

    componentDidUpdate(prevprops:IPowerBoxProps) {        
        if(this.props && this.props.data && prevprops.data !== this.props.data){
            this.updateLabels();           
        }    
    }

    private updateLabels() {
        let dataArray:any[]=[];
        let vLabel:string;
        
        let data = this.props.data;
        let value = data["Exchange_DK1_NO"];        
        value<0?vLabel=locals.Export +": ":vLabel=locals.Import + ": ";     
        dataArray.push({label:locals.JutlandNorway, value:formatAbsNumber(value), xpos:'55', ypos:'66',valueLabel:vLabel }); // 'JYLLAND - NORGE'
                  
        value = data["Exchange_DK1_SE"];
        value<0?vLabel=locals.Export +": ":vLabel=locals.Import + ": ";     
        dataArray.push({label:locals.JutlandSweden, value:formatAbsNumber(value), xpos:'186', ypos:'68',valueLabel:vLabel}); // 'JYLLAND - SVERIGE'

        value = data["Exchange_DK1_GB"]; 
        value<0?vLabel=locals.Export +": ":vLabel=locals.Import + ": ";      
        dataArray.push({label:locals.VikingLink, value:formatAbsNumber(value), xpos:'20', ypos:'185',valueLabel:vLabel});//'Jylland - England'
        
        value = data["Exchange_DK1_NL"];
        value<0?vLabel=locals.Export +": ":vLabel=locals.Import + ": ";     
        dataArray.push({label:locals.JutlandNetherlands, value:formatAbsNumber(value), xpos:'35', ypos:'210',valueLabel:vLabel}); // 'JYLLAND - HOLLAND'
        
        value = data["Exchange_DK1_DE"]; 
        value<0?vLabel=locals.Export +": ":vLabel=locals.Import + ": ";     
        dataArray.push({label:locals.JutlandGermany, value:formatAbsNumber(value), xpos:'100', ypos:'260',valueLabel:vLabel}); //'JYLLAND - TYSKLAND'
        
        value = data["Exchange_DK1_DK2"];    
        value<0?vLabel="-> ":vLabel="<-";     
        dataArray.push({label:locals.TheGreatBelt, value:formatAbsNumber(value), xpos:'170', ypos:'210',valueLabel:vLabel}); // 'STOREBÆLT'
        
        value = data["Exchange_DK2_SE"];  
        value<0?vLabel=locals.Export +": ":vLabel=locals.Import + ": ";     
        dataArray.push({label:locals.ZealandSweden, value:formatAbsNumber(value), xpos:'280', ypos:'150',valueLabel:vLabel}); // 'SJÆLLAND - SVERIGE'
        
        value = data["Exchange_DK2_DE"];        
        value<0?vLabel=locals.Export +": ":vLabel=locals.Import + ": ";     
        dataArray.push({label:locals.ZealandGermany, value:formatAbsNumber(value), xpos:'250', ypos:'230',valueLabel:vLabel}); // 'SJÆLLAND - TYSKLAND'
        
        value = data["Exchange_Bornholm_SE"]; 
        value<0?vLabel=locals.Export +": ":vLabel=locals.Import + ": ";     
        dataArray.push({label:locals.BornholmSweden, value:formatAbsNumber(value), xpos:'290', ypos:'200',valueLabel:vLabel}); //'BORNHOLM - SVERIGE'        
          
        this.setState({
            data: dataArray
        });      
    }
 

    render() {
        let key:number = 1;
        return (   
            <div id="powerLabelBox">
            <div className="map-sidebar-box">     
                <h3>{locals.EnergyExchange}</h3> 
                <ul className="no-bullet">          
                    {this.state && this.state.data.map((itm) => {
                        return<ExternalPowerLabel key={key++} flow={itm.value} label={itm.label} xpos={itm.xpos} ypos={itm.ypos} valueLabel={itm.valueLabel} />
                    })}                                        
                </ul>                                    
            </div>
        </div>       
                      
            
        );
    }

    // private formatNumber(value:number) {
    //     let num:number = Math.round(value);
    //     num = Math.abs(num);
    //     return num.toLocaleString();
    //  }
}

export default ExternalPowerLabels;   

