import React from 'react';
export interface TransformerStationProps {
    posx:string;
    posy:string;
}
  
class TransformerStation extends React.Component<TransformerStationProps, {}> { 
    render() {        
        let transformData:string = `translate(${this.props.posx} ${this.props.posy})`
        return ( 
            <use width="4" height="4" transform={transformData} xlinkHref="#transformerstation"></use>
         );
    }
}
 
export default TransformerStation;