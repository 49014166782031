import React from 'react';
import { formatAbsNumber } from '../Utility';
export interface PowerLabelProps {
    label: string;
    valueLabel: string;
    flow: number;
    xpos: string;
    ypos: string
}

export interface PowerLabelState {
    fontSize: string;
}

//const fontSize:string = '.2300rem';

class PowerLabel extends React.Component<PowerLabelProps, PowerLabelState> {
    constructor(props: PowerLabelProps,) {
        super(props);
        this.state = { fontSize: '.2300rem' };
    }

    // componentDidMount() {
    //     window.addEventListener('resize', this.updateDimensions);
    // }
    // componentWillUnmount() {
    //     window.removeEventListener('resize', this.updateDimensions);
    // }

    // private updateDimensions = () => {
    //     if (window.innerWidth < 1024 && window.innerWidth > 640)
    //         this.setState({ fontSize: (0.2300 * (1 + ((1024 - window.innerWidth) / 500)) + 'rem') });
    //     else
    //         this.setState({ fontSize: '0.2300rem' });
    // };

    render() {
        let style: React.CSSProperties | object = {
            display: 'inline-block',
            padding: '4px',
            textTransform: 'uppercase',
            color: '#02525e',
            border: 'solid 1px rgba(255,255,255,0.9)',
            backgroundColor: 'rgba(230,230,230,0.9)',
        }

        let titleStyle: React.CSSProperties | object = {
            fontSize: this.state.fontSize,
            fontFamily: "DINNextLTPro-Medium",
            textAlign: 'left'
        }

        let valueStyle: React.CSSProperties | object = {
            fontSize: this.state.fontSize,
            fontFamily: "DINNextLTPro-Regular",
            textAlign: 'left'
        }

        let showLabel:boolean=true;
        if( parseInt(formatAbsNumber(this.props.flow))===0)
            showLabel=false;

        return (
            <foreignObject className="HideOnSmallScreen" x={this.props.xpos} y={this.props.ypos} width="200" height="50">
                <div style={style}>
                    <div style={titleStyle}>{this.props.label}</div>                    
                    <div style={valueStyle}>{showLabel && this.props.valueLabel}{formatAbsNumber(this.props.flow)} MW</div>                    
                </div>
            </foreignObject>
        );
    }
}

export default PowerLabel;

// display: inline-block;    
// font-size: .75rem;
// line-height: 1.3;
// position: absolute;
// padding: 10px 12px 0 12px;
// transition: opacity 400ms ease-in-out;
// text-transform: uppercase;
// color: #02525e;
// border: solid 2px rgba(255,255,255,0.9);
// background-color: rgba(230,230,230,0.9);    

